import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { StyledTextField, FormStyles, StyledCheckbox } from './styles';
import Checkbox from '@material-ui/core/Checkbox';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { isBlank } from '../../utils/validations';
import { removeWhiteSpaces } from '../../utils/helper';

import Toaster from '../Common/Toaster';
import UserAccessRadio from '../Common/UserAccessRadio';

const formFields = ['username', 'email', 'password'];
class AddEditUser extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      fields: {},
      error: {},
      enabled: true,
      showToaster: false,
      toasterType: 'error',
      toasterMessage: '',
      isEdit: false,
      role: 'read'
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.editRow)) {
      const role = this.getAccessId('read');
      const fields = { confirmed: true, blocked: false, role };
      this.setState({
        fields,
        isEdit: false
      });
    } else {
      const { editRow } = this.props;
      const { role } = editRow;
      editRow.role = this.getAccessId(editRow.role.toLowerCase());
      this.setState({
        fields: editRow,
        isEdit: true,
        role: role.toLowerCase(),
        enabled: editRow.enabled
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.user, this.props.user)) {
      this.processResponse(prevProps);
    }
  }

  processResponse = prevProps => {
    if (this.props.user.addUserStatus != prevProps.user.addUserStatus) {
      if (this.props.user.addUserStatus === true) {
        this.props.changeAddUserFormCloseStatus(true);
      }
      if (this.props.user.addUserStatus === false) {
        this.setState({
          showToaster: true,
          toasterType: 'error',
          toasterMessage: 'Something went wrong'
        });
      }
    }
  };

  handleEnabledCheck = () => {
    let fields = this.state.fields;
    let value = !this.state.enabled;
    fields.blocked = !value;
    this.setState({
      fields,
      enabled: value
    });
  };

  handleInputChange = e => {
    let fields = this.state.fields;
    fields[e.target.id] = e.target.value;
    this.setState({
      fields
    });
    this.props.setFormFields(fields);
  };

  getAccessId = value => {
    const { user } = this.props;
    const { roles } = user || [];
    const roleObj = roles.filter(role => {
      return role.type === value;
    });

    if (roleObj[0]) {
      return roleObj[0].id;
    }

    return null;
  };

  handleAccessInputChange = e => {
    let fields = this.state.fields;
    const accessId = this.getAccessId(e.target.value);
    fields[e.target.id] = accessId;
    this.setState({
      fields,
      role: e.target.value
    });
    this.props.setFormFields(fields);
  };

  validateForm() {
    const { fields, isEdit } = this.state;
    let error = [];
    let dateError = false;
    let isFormValid = true;

    for (let i in formFields) {
      if (isEdit === true && formFields[i] === 'password') {
        continue;
      }
      if (isBlank(fields[formFields[i]])) {
        error[formFields[i]] = true;
        isFormValid = false;
      } else {
        error[formFields[i]] = false;
      }
    }

    this.setState({
      error
    });

    return isFormValid;
  }

  handleFormSubmit = e => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = this.state.fields;
      fields = removeWhiteSpaces(fields);
      if (this.state.isEdit) {
        this.props.editUser(fields._id, fields);
      } else {
        this.props.addUser(fields);
      }
    }
  };

  handleToasterClose = () => {
    this.setState({
      showToaster: false
    });
  };

  render() {
    const { classes } = this.props;
    const {
      fields,
      error,
      enabled,
      toasterMessage,
      toasterType,
      showToaster
    } = this.state;
    return (
      <div>
        <Toaster
          message={toasterMessage}
          type={toasterType}
          open={showToaster}
          handleClose={this.handleToasterClose}
        />
        <div className={classes.heading}>{'Add User'}</div>
        <form method="post" name="addUserForm" onSubmit={this.handleFormSubmit}>
          <StyledTextField
            fullWidth
            id="username"
            label="Username"
            placeholder="Enter Username"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={this.handleInputChange}
            value={fields.username}
            error={error.username}
            helperText={error.username ? 'Please Enter Username' : null}
          />
          <StyledTextField
            fullWidth
            error={error.email}
            id="email"
            label="Email Address"
            placeholder="Enter Email Address"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={this.handleInputChange}
            value={fields.email}
            helperText={error.email ? 'Please Enter Email Address' : null}
            disabled={this.state.isEdit}
          />
          <StyledTextField
            fullWidth
            error={error.password}
            id="password"
            type="password"
            label="Password"
            placeholder="Enter Password"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={this.handleInputChange}
            value={fields.password}
            helperText={error.password ? 'Please Enter Password' : null}
          />
          <div className={classes.fullWidthDiv}>
            <UserAccessRadio
              handleChange={this.handleAccessInputChange}
              defaultValue={this.state.role || ''}
            />
          </div>
          <StyledCheckbox>
            Active
            <Checkbox
              checked={enabled}
              onChange={this.handleEnabledCheck}
              name="checkedB"
              color="primary"
            />
          </StyledCheckbox>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            startIcon={<SaveIcon />}
            type="submit"
            id="save_user_btn"
          >
            Save
          </Button>
        </form>
      </div>
    );
  }
}

export default withStyles(FormStyles)(AddEditUser);
