const { REACT_APP_API_BASE_URL, REACT_APP_MOBILE_API_BASE_URL } = process.env;

export const API_BASE_URL = REACT_APP_API_BASE_URL;
export const MOBILE_API_BASE_URL =
  REACT_APP_MOBILE_API_BASE_URL || 'https://staging-api.tripper.co.id';

export const ERROR_MESSAGE = {
  '401': 'Request failed with status code 401'
};

export * from './campaign';
export * from './advertisement';
