import React from 'react';
import Footer from './Footer';
import Leaderboard from './Leaderboard';
import Popup from './Popup';
import PreRoll from './PreRoll';
import MidRoll from './MidRoll';
import Discovery from './Discovery';

class AdvertisementType extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    const type = this.props.fields.advertisementType;
    return (
      <div>
        {type === 'footer' && (
          <Footer
            handleChange={this.props.handleChange}
            fields={this.props.fields.footer || {}}
            error={this.props.error.footer || {}}
          />
        )}
        {type === 'leaderboard' && (
          <Leaderboard
            handleChange={this.props.handleChange}
            fields={this.props.fields.leaderboard || {}}
            airlineSelected={this.props.fields.airlineAllocation || []}
            error={this.props.error.leaderboard}
          />
        )}
        {type === 'popUp' && (
          <Popup
            handleChange={this.props.handleChange}
            fields={this.props.fields.popUp || {}}
            error={this.props.error.popUp || {}}
          />
        )}
        {type === 'preroll' && (
          <PreRoll
            handleChange={this.props.handleChange}
            fields={this.props.fields.preroll || {}}
            airlineAllocation={this.props.fields.airlineAllocation || []}
            videoContent={this.props.videoContent}
            error={this.props.error.preroll || {}}
          />
        )}
        {type === 'midroll' && (
          <MidRoll
            handleChange={this.props.handleChange}
            fields={this.props.fields.midroll || {}}
            airlineAllocation={this.props.fields.airlineAllocation || []}
            videoContent={this.props.videoContent}
            error={this.props.error.midroll || {}}
          />
        )}
        {type === 'discovery' && (
          <Discovery
            handleChange={this.props.handleChange}
            fields={this.props.fields.discovery || {}}
          />
        )}
      </div>
    );
  }
}

export default AdvertisementType;
