import React, { useState } from 'react';
import { FullWidthContainer, StyledTextField } from './styles';
import Multiselect from '../Common/Multiselect';
import PlacementIndexTable from '../Common/PlacementIndexTable';
import Checkbox from '@material-ui/core/Checkbox';
import { PAGES_TO_INCLUDE, PLACEMENT_INDEX } from '../../constants';
import { SubTitle, StyledCheckbox, TargetLinkDiv } from './styles';
import { get } from 'lodash';
import TextField from '@material-ui/core/TextField';

const Leaderboard = ({ fields, handleChange, airlineSelected, error }) => {
  const [isChecked, setIsChecked] = useState(fields.auto || false);

  const handleCheckboxChange = () => {
    handleChange({
      target: {
        type: 'leaderboard',
        id: 'auto',
        value: !isChecked
      }
    });

    setIsChecked(!isChecked);
  };

  const handleSelectChange = (id, value) => {
    handleChange({
      target: {
        type: 'leaderboard',
        id: id,
        value: value
      }
    });
  };

  const handleInputChange = e => {
    handleChange({
      target: {
        type: 'leaderboard',
        id: e.target.id,
        value: e.target.value
      }
    });
  };

  return (
    <FullWidthContainer>
      {airlineSelected.map(function(value, index) {
        return (
          <div>
            <PlacementIndexTable
              airline={value}
              handleSelectChange={handleSelectChange}
              fields={fields}
              options={PAGES_TO_INCLUDE}
              fieldName="pageToInclude"
              label="Pages to Include"
              error={error}
              placementIndexOptions={PLACEMENT_INDEX}
            />
          </div>
        );
      })}
      <SubTitle>Target Link (Online)</SubTitle>

      <TextField
        fullWidth
        id="targetLinkOnlineAndroid"
        label="Target Link (Install Android)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOnlineAndroid}
        variant="outlined"
      />

      <TextField
        fullWidth
        id="targetLinkOnlineIos"
        label="Target Link (Install iOS)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOnlineIos}
        variant="outlined"
      />

      <TargetLinkDiv>
        <TextField
          id="targetLinkOnlineLearnMore.label"
          label="Target Link Label"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOnlineLearnMore.label', 'Learn More')}
          variant="outlined"
        />

        <TextField
          id="targetLinkOnlineLearnMore.link"
          label="Target Link (Learn More)"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOnlineLearnMore.link', '')}
          variant="outlined"
        />
      </TargetLinkDiv>

      <SubTitle>Target Link (Offline)</SubTitle>

      <TextField
        fullWidth
        id="targetLinkOfflineAndroid"
        label="Target Link (Install Android)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOfflineAndroid}
        variant="outlined"
      />

      <TargetLinkDiv>
        <TextField
          id="targetLinkOfflineLearnMore.label"
          label="Target Link Label"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOfflineLearnMore.label', 'Learn More')}
          variant="outlined"
        />

        <TextField
          id="targetLinkOfflineLearnMore.link"
          label="Target Link (Learn More)"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOfflineLearnMore.link', '')}
          variant="outlined"
        />
      </TargetLinkDiv>
    </FullWidthContainer>
  );
};

export default Leaderboard;
