import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Campaign from './components/Campaign/CampaignContainer';
import Login from './components/Login/LoginContainer';
import UserManagement from './components/UserManagement';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/view/campaign">
            <Campaign />
          </Route>
          <Route path="/view/login">
            <Login />
          </Route>
          <Route path="/view/user">
            <UserManagement />
          </Route>
          <Route exact path="/">
            <Redirect to="/view/login" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
