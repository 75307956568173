import axios from 'axios';
import { API_BASE_URL, ERROR_MESSAGE } from '../constants';
import * as actions from './index';
import { logout } from './loginAction';

export const getUserRolesAction = () => {
  return dispatch => {
    dispatch(actions.userRoleStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/users-permissions/roles`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.userRoleSuccess(response.data.roles));
        } else {
          dispatch(actions.userRoleError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.userRoleError());
      });
  };
};

export const addUserAction = payload => {
  return dispatch => {
    dispatch(actions.addUserStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/users`,
      headers: { Authorization: `Bearer ${token}` },
      data: payload
    })
      .then(function(response) {
        if (response.status === 201 && response.statusText === 'Created') {
          dispatch(actions.addUserSuccess());
        } else {
          dispatch(actions.addUserError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.addUserError());
      });
  };
};

export const listUsersAction = () => {
  return dispatch => {
    dispatch(actions.listUsersRequestStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/users`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          if (response.data) {
            const users = response.data.map(e => ({
              _id: e._id,
              username: e.username,
              email: e.email,
              role: e.role.name,
              enabled: !e.blocked
            }));
            dispatch(actions.listUsersRequestSuccess(users));
          } else {
            dispatch(actions.listUsersRequestError());
          }
        } else {
          dispatch(actions.listUsersRequestError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.listUsersRequestError());
      });
  };
};

export const updateUserAction = (id, data) => {
  return dispatch => {
    dispatch(actions.addUserStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'PUT',
      url: `${API_BASE_URL}/users/${id}`,
      data: data,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.addUserSuccess());
        } else {
          dispatch(actions.addUserError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.addUserError());
      });
  };
};
