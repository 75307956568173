import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import {
  CollapsibleTableCell,
  CollapsibleTableRow,
  StyledTableCell
} from './styles';

import Multiselect from '../Multiselect';
import { PLACEMENT_INDEX } from '../../../constants';

export default function CustomRow(props) {
  const {
    isItemSelected,
    handleClick,
    handleEnabledCheck,
    row,
    headCells,
    features,
    setCollapseRowIdStatus,
    collapsedRow
  } = props;

  useEffect(() => {
    if (collapsedRow && row.id === collapsedRow.rowId) {
      setOpen(collapsedRow.status);
    }
  }, [collapsedRow]);

  const [open, setOpen] = React.useState(false);

  const handleEditClick = () => features.editActionHandler(row);

  const handleDeleteClick = () => features.deleteActionHandler(row);

  const handleRowClick = rowId => {
    if (features.enableSelectedCollapseStatus) {
      let statusObj = { rowId, status: !open };
      setCollapseRowIdStatus(statusObj);
    }
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={`custom-row-${row._id}`}
        selected={isItemSelected}
        id={`custom-row-${row._id}`}
      >
        {features.enableCheckbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              onClick={event => handleClick(event, row._id)}
              id={`select_${row._id}`}
            />
          </TableCell>
        ) : null}
        {headCells.map(k =>
          !k.hidden ? (
            <StyledTableCell
              key={`custom-cell-${k.id}`}
              enablecollapse={`${features.enableCollapse}`}
              onClick={() => handleRowClick(row.id)}
            >
              {k.processor ? k.processor(row) : row[k.id]}
            </StyledTableCell>
          ) : null
        )}
        {features.enableEnabledCheckbox ? (
          <TableCell>
            <Checkbox
              id={`enable_${row._id}`}
              checked={row.enabled}
              onChange={e => handleEnabledCheck(row._id, row.enabled)}
              name="checkedB"
              color="primary"
            />
          </TableCell>
        ) : null}
        {features.enableActions ? (
          <TableCell>
            <IconButton
              id={`edit_${row._id}`}
              onClick={handleEditClick}
              color="primary"
            >
              <EditIcon />
            </IconButton>
            {!features.hasOwnProperty('enableDeleteAction') ||
            (features.enableDeleteAction &&
              features.enableDeleteAction == true) ? (
              <IconButton
                id={`delete_${row._id}`}
                onClick={handleDeleteClick}
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </TableCell>
        ) : null}
        {features.enableSelectBoxColumn
          ? features.numberOfSelectBoxColumn.map(
              (value: undefined, index: number) => (
                <StyledTableCell
                  key={`custom-cell-${features.selectBoxAttributes[index].elementId}`}
                >
                  {
                    <Multiselect
                      labelId={`${features.selectBoxAttributes[index].elementId}_${row._id}_label`}
                      label={`${features.selectBoxAttributes[index].label}`}
                      elementId={`${features.selectBoxAttributes[index].elementId}_${row._id}`}
                      options={features.selectBoxAttributes[index].options}
                      multiple={false}
                      setValue={
                        features.selectBoxAttributes[index].handleSelectChange
                      }
                      defaultValue={features.selectBoxAttributes[
                        index
                      ].defaultValueProcessor(
                        row._id,
                        features.selectBoxAttributes[index].elementId
                      )}
                      error={features.selectBoxAttributes[index].errorProcessor(
                        row._id
                      )}
                    />
                  }
                </StyledTableCell>
              )
            )
          : null}
      </TableRow>
      {features.enableCollapse ? (
        <CollapsibleTableRow>
          <CollapsibleTableCell colSpan={24}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>{features.collapseRowData(row)}</Box>
            </Collapse>
          </CollapsibleTableCell>
        </CollapsibleTableRow>
      ) : null}
    </React.Fragment>
  );
}

CustomRow.propTypes = {
  row: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  headCells: PropTypes.array.isRequired,
  isItemSelected: PropTypes.bool.isRequired
};
