import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useStyles } from './styles';

export default function RadioButtonsGroup(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.defaultValue);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props]);

  const handleChange = event => {
    setValue(event.target.value);
    let thisValue = [];
    if (event.target.value === 'both') {
      thisValue.push('male');
      thisValue.push('female');
    } else {
      thisValue.push(event.target.value);
    }
    props.handleChange({
      target: {
        id: 'gender',
        value: thisValue
      }
    });
  };

  const getValue = () => {
    if (Array.isArray(value) && value.length > 1) {
      return 'both';
    }
    if (Array.isArray(value) && value.length == 1) {
      return value[0];
    }
    return value;
  };

  return (
    <div className={classes.tableDiv}>
      <FormLabel classes={{ root: classes.radioLabel }} component="legend">
        Gender:
      </FormLabel>
      <div className={classes.radioGroup}>
        <RadioGroup
          classes={{ row: classes.radioGroup }}
          aria-label="gender"
          name="gender1"
          value={getValue()}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="female" control={<Radio />} label="Female" />
          <FormControlLabel value="male" control={<Radio />} label="Male" />
          <FormControlLabel value="both" control={<Radio />} label="Both" />
        </RadioGroup>
      </div>
    </div>
  );
}
