export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
};

export const descendingDateComparator = (a, b) => {
  a = new Date(a);
  b = new Date(b);

  if (b < a) return -1;

  if (b > a) return 1;

  return 0;
};

export const descendingComparator = (a, b) => b.localeCompare(a);
