import axios from 'axios';
import { API_BASE_URL } from '../constants';
import * as actions from './index';

export const loginAction = (username, password) => {
  return dispatch => {
    dispatch(actions.loginRequestStart());
    let payload = { identifier: username, password };
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/auth/local/`,
      data: payload
    })
      .then(function(response) {
        if (response.status === 200) {
          if (response.data && response.data.jwt) {
            localStorage.setItem('token', response.data.jwt);
            dispatch(
              actions.loginRequestSuccess(
                (response.data &&
                  response.data.user &&
                  response.data.user.role &&
                  response.data.user.role.type) ||
                  'read'
              )
            );
          } else {
            dispatch(actions.loginRequestError());
          }
        } else {
          dispatch(actions.loginRequestError());
        }
      })
      .catch(function() {
        dispatch(actions.loginRequestError());
      });
  };
};

export const logout = (username, password) => {
  localStorage.removeItem('token');
  return dispatch => {
    dispatch(actions.logout());
  };
};
