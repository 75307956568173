import React, { useState } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { SubTitle, SelectContainer } from './styles';
import Multiselect from '../Common/Multiselect';
import {
  PAGES_TO_INCLUDE,
  PLACEMENT_INDEX,
  TIME_INTERVAL
} from '../../constants';
import CustomTable from './Table/index';

const Leaderboard = ({
  airline,
  handleSelectChange,
  fields,
  options,
  fieldName,
  label,
  error,
  numberOfSelectColumn,
  placementIndexOptions
}) => {
  useDeepCompareEffectNoCheck(() => {
    const placementIndex = fields.placementIndex;
    const timeInterval = fields.timeInterval;
    let pageToInclude = getPageToIncludeValues();
    if (placementIndex && placementIndex[`${airline}`]) {
      placementIndex[`${airline}`].forEach(function(value, index) {
        const valueIndex = pageToInclude.findIndex(ele => ele === value.key);
        if (valueIndex === -1) {
          placementIndex[`${airline}`].splice(index, 1);
          handleSelectChange('placementIndex', placementIndex);
        }
      });
    }
    if (timeInterval && timeInterval[`${airline}`]) {
      timeInterval[`${airline}`].forEach(function(value, index) {
        const valueIndex = pageToInclude.findIndex(ele => ele === value.key);
        if (valueIndex === -1) {
          timeInterval[`${airline}`].splice(index, 1);
          handleSelectChange('timeInterval', timeInterval);
        }
      });
    }
  }, [fields[fieldName]]);

  const handlePagesToIncludeSelectChange = (id, value) => {
    let idList = id.split('_');
    let airline = idList[1];
    let pageToInclude = fields[fieldName];
    if (!pageToInclude) {
      pageToInclude = [];
      let pageToIncludeObj = { airline, value };
      pageToInclude.push(pageToIncludeObj);
      handleSelectChange(fieldName, pageToInclude, airline);
      return;
    }

    const thisIndex = pageToInclude.findIndex(
      value => value.airline === airline
    );
    if (thisIndex != -1) {
      let thisObj = pageToInclude[thisIndex];
      thisObj.value = value;
      pageToInclude[thisIndex] = thisObj;
    } else {
      let thisObj = { airline, value };
      pageToInclude.push(thisObj);
    }
    handleSelectChange(fieldName, pageToInclude, airline);
    return;
  };

  const handlePlacementIndexSelectChange = (id, value) => {
    let idList = id.split('_');
    let selectBoxId = idList[0];
    id = idList[1];
    let selectObj = fields[selectBoxId];
    if (!selectObj) {
      let thisSelectObj = {};
      thisSelectObj[`${airline}`] = [];
      thisSelectObj[`${airline}`].push({ key: id, value });
      selectObj = thisSelectObj;
      handleSelectChange(selectBoxId, selectObj);
      return;
    }

    if (!selectObj[`${airline}`]) {
      selectObj[`${airline}`] = [];
      selectObj[`${airline}`].push({ key: id, value });
      handleSelectChange(selectBoxId, selectObj);
      return;
    }

    let index = selectObj[`${airline}`].findIndex(ele => ele.key === id);
    if (index === -1) {
      selectObj[`${airline}`].push({ key: id, value });
    } else {
      selectObj[`${airline}`][index].value = value;
    }

    handleSelectChange(selectBoxId, selectObj);
    return;
  };

  const defaultValueProcess = (id, elementId) => {
    let selectBoxObj =
      (fields[elementId] && (fields[elementId][`${airline}`] || [])) || [];
    let obj = selectBoxObj.filter(function(ele) {
      return ele.key === id;
    });
    if (obj && obj[0]) {
      return [obj[0].value];
    }
    return [];
  };

  const handleErrorProcess = id => {
    if (error && error.placementIndex) {
      let errors = error.placementIndex;
      let airlineError = errors[airline] || [];
      let errorIndex = airlineError.findIndex(ele => ele === id);
      if (errorIndex != -1) {
        return true;
      }
    }
    return false;
  };

  const headerCells = [
    {
      id: fieldName,
      type: 'text',
      label: label,
      sortable: true
    }
  ];

  const features = {
    enableCheckbox: false,
    enableActions: false,
    enableCollapse: false,
    enableSelectBoxColumn: true,
    numberOfSelectBoxColumn: numberOfSelectColumn
      ? [...Array(numberOfSelectColumn)]
      : [...Array(1)],
    defaultSortColumn: fieldName,
    defaultSortOrder: 'asc',
    selectBoxAttributes: [
      {
        label: 'Placement Index',
        options: placementIndexOptions,
        handleSelectChange: handlePlacementIndexSelectChange,
        defaultValueProcessor: defaultValueProcess,
        elementId: 'placementIndex',
        fields: fields.placementIndex,
        errorProcessor: handleErrorProcess
      },
      {
        label: 'Time Interval(Min)',
        options: TIME_INTERVAL,
        handleSelectChange: handlePlacementIndexSelectChange,
        defaultValueProcessor: defaultValueProcess,
        elementId: 'timeInterval',
        fields: fields.placementIndex,
        errorProcessor: handleErrorProcess
      }
    ]
  };

  const getPageToIncludeValues = () => {
    const pageToInclude = fields[fieldName] || [];
    const obj = pageToInclude.filter(function(ele, index) {
      return ele.airline === airline;
    });

    if (obj && obj[0]) {
      return obj[0].value;
    }
    return [];
  };

  const getPagesToIncludeTableList = () => {
    const pageToInclude = getPageToIncludeValues();
    let pageToIncludeList = [];
    pageToInclude.forEach(function(page, index) {
      let thisObj = {};
      thisObj[fieldName] = page;
      thisObj._id = page;
      pageToIncludeList.push(thisObj);
    });
    return pageToIncludeList;
  };

  return (
    <div>
      <SubTitle>{`AIRLINE - ${airline}`}</SubTitle>
      <SelectContainer>
        <Multiselect
          labelId={`${fieldName}Label`}
          label={label}
          elementId={`${fieldName}_${airline}`}
          options={options}
          multiple={true}
          setValue={handlePagesToIncludeSelectChange}
          defaultValue={getPageToIncludeValues()}
        />
      </SelectContainer>
      <CustomTable
        list={getPagesToIncludeTableList()}
        headCells={headerCells}
        features={features}
        id={`placementIndex_list_${airline}`}
      />

      {
        // <Multiselect
        //   labelId="placementIndexLabel"
        //   label="Placement Index"
        //   elementId="placementIndex"
        //   options={PLACEMENT_INDEX}
        //   multiple={false}
        //   setValue={handleSelectChange}
        //   defaultValue={fields.placementIndex || []}
        // />
      }
    </div>
  );
};

export default Leaderboard;
