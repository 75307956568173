import { checkDateOutOfRange } from './dateTime';
import { validationsList } from './advertisementTypeValidationsList';

const processAirlineLevelPlacementIndexCheck = (
  placementIndex,
  airline,
  error,
  pageValue,
  placementIndexValue,
  preOccupiedObjValue,
  thisCampaignStartDate,
  thisCampaignEndDate
) => {
  let airlineKeys = Object.keys(placementIndex);
  for (let k = 0; k < airlineKeys.length; k++) {
    let prePagesToInclude = placementIndex[airlineKeys[k]];
    prePagesToInclude.forEach(function(thisPage, thisIndex) {
      if (
        airline === airlineKeys[k] &&
        thisPage.key === pageValue &&
        thisPage.value === placementIndexValue
      ) {
        const res = checkDateOutOfRange(
          thisCampaignStartDate,
          thisCampaignEndDate,
          preOccupiedObjValue.startDate,
          preOccupiedObjValue.endDate
        );

        if (res.status === false) {
          error.status = true;
          const { campaignName, advertisementName } = preOccupiedObjValue;
          let thisObj = {
            value: placementIndexValue,
            key: pageValue,
            preOccupiedObj: {
              campaignName,
              advertisementName,
              airline: airlineKeys[k],
              errorType: 'placementIndex'
            },
            data: res.data
          };
          error.data.push(thisObj);
        }
      }
    });
  }
};

const processPlacementIndexCheck = (
  pagesIncluded,
  airline,
  preOccupiedValueList,
  error,
  thisCampaignStartDate,
  thisCampaignEndDate
) => {
  pagesIncluded.forEach(function(page, index) {
    let { key, value } = page;
    preOccupiedValueList.forEach(function(obj, preIndex) {
      let placementIndex = obj.placementIndex;
      if (!placementIndex || typeof placementIndex !== 'object') {
        return;
      }
      processAirlineLevelPlacementIndexCheck(
        placementIndex,
        airline,
        error,
        key,
        value,
        obj,
        thisCampaignStartDate,
        thisCampaignEndDate
      );
    });
  });
};

const placementIndexCheck = (fields, preOccupiedValueObj) => {
  let error = { status: false, data: [] };
  let preOccupiedValueList =
    preOccupiedValueObj[fields.advertisementType] || [];
  if (fields[fields.advertisementType]) {
    const adTypeObj = fields[fields.advertisementType];
    const { placementIndex } = adTypeObj;

    if (!placementIndex || typeof placementIndex !== 'object') {
      return error;
    }

    let keys = Object.keys(placementIndex);
    for (let i = 0; i < keys.length; i++) {
      let pagesIncluded = placementIndex[keys[i]];
      processPlacementIndexCheck(
        pagesIncluded,
        keys[i],
        preOccupiedValueList,
        error,
        fields.startDate,
        fields.endDate
      );
    }
  }
  return error;
};

const perPageCheck = (fields, preOccupiedValueObj) => {
  let error = { status: false, data: [] };
  let preOccupiedValueList =
    preOccupiedValueObj[fields.advertisementType] || [];
  if (!fields[fields.advertisementType]) {
    return error;
  }
  const adTypeObj = fields[fields.advertisementType];
  const { pageToInclude } = adTypeObj;
  const { airlineAllocation } = fields;
  if (!pageToInclude) {
    return error;
  }
  if (!airlineAllocation) {
    return error;
  }

  for (let i = 0; i < pageToInclude.length; i++) {
    let page = pageToInclude[i];
    preOccupiedValueList.forEach(function(value, index) {
      const thisPagesToInclude = value.pagesToInclude || [];
      const thisAirlineAllocation = value.airlineAllocation || [];
      const found = airlineAllocation.some(
        r => thisAirlineAllocation.indexOf(r) >= 0
      );
      if (found === true && thisPagesToInclude.indexOf(page) != -1) {
        const res = checkDateOutOfRange(
          fields.startDate,
          fields.endDate,
          value.startDate,
          value.endDate
        );
        if (res.status === false) {
          error.status = true;
          const { campaignName, advertisementName } = value;
          let thisObj = {
            value: page,
            data: res.data,
            preOccupiedObj: {
              campaignName,
              advertisementName
            }
          };
          error.data.push(thisObj);
        }
      }
    });
  }
  return error;
};

const perVideoMaxCheck = (fields, preOccupiedValueObj) => {
  let error = { status: false, data: [] };
  let preOccupiedValueList =
    preOccupiedValueObj[fields.advertisementType] || [];
  const maxVideoCount = validationsList.maxPerVideo[fields.advertisementType];
  if (!fields[fields.advertisementType]) {
    return error;
  }
  const adTypeObj = fields[fields.advertisementType];
  const { videosToInclude } = adTypeObj;

  if (!videosToInclude) {
    return error;
  }
  let videoCountObject = {};

  for (let i = 0; i < videosToInclude.length; i++) {
    const videoObj = videosToInclude[i];
    const airlineId = videoObj.airline;
    const contentIdList = videoObj.contentId;

    contentIdList.forEach(function(video, i) {
      preOccupiedValueList.forEach(function(value, index) {
        const thisVideoContentList = value.videosToInclude || [];
        const thisVideoObj = thisVideoContentList.filter(function(item) {
          return item.airline === airlineId;
        });
        if (thisVideoObj[0] && thisVideoObj[0].contentId) {
          const thisVideoList = thisVideoObj[0].contentId;
          if (thisVideoList.indexOf(video) != -1) {
            const res = checkDateOutOfRange(
              fields.startDate,
              fields.endDate,
              value.startDate,
              value.endDate
            );
            if (res.status === false) {
              if (!videoCountObject[video]) {
                videoCountObject[video] = 0;
              }
              videoCountObject[video] = videoCountObject[video] + 1;
              if (videoCountObject[video] >= maxVideoCount) {
                error.status = true;
                const { campaignName, advertisementName } = value;
                let thisObj = {
                  value: video,
                  data: res.data,
                  preOccupiedObj: {
                    campaignName,
                    advertisementName,
                    airline: airlineId
                  }
                };
                error.data.push(thisObj);
              }
            }
          }
        }
      });
    });
  }
  return error;
};

export default {
  placementIndexCheck,
  perPageCheck,
  perVideoMaxCheck
};
