import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MARKETING_STATUS } from '../../constants';

const Container = styled.div`
  margin: 20px;
`;

const FormContainer = styled.div`
  position: fixed;
  top: 20%;
  width: 80%;
  left: 10%;
`;

const ViewHeader = styled.div`
  margin: 20px 0px 0px 0px;
  font-size: 22px;
`;

const StyledButton = withStyles(theme => ({
  root: {
    margin: '20px 10px'
  }
}))(Button);

const StyledTextField = withStyles(theme => ({
  root: {
    margin: 8
  }
}))(TextField);

const FormStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  heading: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1)
  },
  fullWidthDiv: {
    width: '100%',
    margin: '8px',
    'min-height': '50px'
  },
  halfWidth: {
    width: '50%'
  },
  halfWidthMargin: {
    width: '100%',
    margin: '8px'
  },
  secondHeading: {
    float: 'left'
  }
});

const StyledMarketingStatus = styled.span`
  color: ${props =>
    props.status === MARKETING_STATUS.EXPIRED ? 'red' : 'green'};
`;

const AgeDiv = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  margin-left: 8px;
  grid-template-columns: 1fr 1fr;
`;

export {
  AgeDiv,
  Container,
  FormContainer,
  FormStyles,
  StyledButton,
  StyledMarketingStatus,
  StyledTextField,
  ViewHeader
};
