import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MARKETING_STATUS } from '../../constants';

const Container = styled.div`
  margin: 20px;
`;

const FormContainer = styled.div`
  position: absolute;
  top: 20%;
  width: 80%;
  left: 10%;
`;

const ViewHeader = styled.div`
  margin: 20px 0px 0px 0px;
  font-size: 28px;
`;

const StyledButton = withStyles(theme => ({
  root: {
    margin: '20px 10px'
  }
}))(Button);

const StyledTextField = withStyles(theme => ({
  root: {
    margin: 8
  }
}))(TextField);

const StyledCheckbox = styled.div`
  text-align: left !important;
  width: 100%;
  margin: 15px 8px;
`;

const FormStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  heading: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1)
  },
  fullWidthDiv: {
    width: '100%',
    margin: '8px'
  },
  halfWidth: {
    width: '50%'
  },
  error: {
    color: '#611A15',
    backgroundColor: '#FDECEA'
  }
});

const StyledMarketingStatus = styled.span`
  color: ${props =>
    props.status === MARKETING_STATUS.EXPIRED ? 'red' : 'green'};
`;

export {
  Container,
  FormContainer,
  FormStyles,
  StyledButton,
  StyledCheckbox,
  StyledMarketingStatus,
  StyledTextField,
  ViewHeader
};
