import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useStyles } from './styles';

export default function RadioButtonsGroup(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.defaultValue);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props]);

  const handleChange = event => {
    setValue(event.target.value);
    props.handleChange({
      target: {
        id: 'role',
        value: event.target.value
      }
    });
  };

  return (
    <div className={classes.tableDiv}>
      <FormLabel classes={{ root: classes.radioLabel }} component="legend">
        Access:
      </FormLabel>
      <div className={classes.radioGroup}>
        <RadioGroup
          classes={{ row: classes.radioGroup }}
          aria-label="role"
          name="role1"
          value={value}
          onChange={handleChange}
          row
        >
          <FormControlLabel value="read" control={<Radio />} label="Read" />
          <FormControlLabel value="write" control={<Radio />} label="Write" />
          <FormControlLabel value="admin" control={<Radio />} label="Admin" />
        </RadioGroup>
      </div>
    </div>
  );
}
