import axios from 'axios';
import { API_BASE_URL, ERROR_MESSAGE } from '../constants';
import * as actions from './index';
import cloneDeep from 'lodash/cloneDeep';
import { setOccupiedValuesForAdvertisementType } from '../utils/advertisementTypeMethods';
import { greaterThanDateComparator } from '../utils/dateTime';
import { logout } from './loginAction';

export const addNewCampaignAction = payload => {
  return dispatch => {
    dispatch(actions.addCampaignRequestStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/campaigns`,
      data: payload,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.addCampaignRequestSuccess());
        } else {
          dispatch(actions.addCampaignRequestError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.addCampaignRequestError());
      });
  };
};

export const editCampaignAction = payload => {
  return dispatch => {
    dispatch(actions.addCampaignRequestStart());
    const token = localStorage.getItem('token');
    const data = cloneDeep(payload);
    delete data.id;
    axios({
      method: 'PUT',
      url: `${API_BASE_URL}/campaigns/${payload.id}`,
      data: data,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.addCampaignRequestSuccess());
        } else {
          dispatch(actions.addCampaignRequestError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.addCampaignRequestError());
      });
  };
};

const setAlreadyOccupiedAdvertismentValues = data => {
  let alreadyOccupiedAdvertismentValues = {};
  data.forEach(function(campaign, index) {
    const { advertisements } = campaign;
    if (!greaterThanDateComparator(new Date(), campaign.endDate)) {
      advertisements.forEach(function(advertisement, adIndex) {
        setOccupiedValuesForAdvertisementType(
          campaign,
          advertisement,
          alreadyOccupiedAdvertismentValues
        );
      });
    }
  });
  return alreadyOccupiedAdvertismentValues;
};

export const listCampaignsActions = () => {
  return dispatch => {
    dispatch(actions.listCampaignRequestStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/campaigns`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          const data = response.data;
          let occupiedValues = {};
          if (data) {
            occupiedValues = setAlreadyOccupiedAdvertismentValues(data);
            data.map(d => {
              const ads = d.advertisements || [];
              d.advertisements = ads.map(e => ({
                advertisementType: e.advertisementType,
                _id: e._id,
                advertisementId: e.advertisementId,
                advertisementName: e.advertisementName,
                updatedAt: e.updatedAt
              }));

              return d;
            });
          }
          dispatch(actions.listCampaignRequestSuccess(data));
          dispatch(
            actions.setAlreadyOccupiedAdvertismentValues(occupiedValues)
          );
        } else {
          dispatch(actions.setAlreadyOccupiedAdvertismentValues({}));
          dispatch(actions.listCampaignRequestError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.setAlreadyOccupiedAdvertismentValues({}));
        dispatch(actions.listCampaignRequestError());
      });
  };
};

export const deleteCampaignAction = id => {
  return dispatch => {
    dispatch(actions.deleteCampaignRequestStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'DELETE',
      url: `${API_BASE_URL}/campaigns/${id}`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.deleteCampaignRequestSuccess());
        } else {
          dispatch(actions.deleteCampaignRequestError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.deleteCampaignRequestError());
      });
  };
};

export const syncCampaigns = campaigns => {
  return dispatch => {
    dispatch(actions.syncCampaignRequestStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/campaigns/sync`,
      data: { campaigns },
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (
          response.status === 200 &&
          response.data.code === 'dsd_039'
        ) {
          dispatch(actions.syncCampaignRequestSuccess());
        } else {
          dispatch(actions.syncCampaignRequestError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.syncCampaignRequestError());
      });
  };
};

export const setCampaignCollapseRowIdStatus = status => {
  return dispatch => {
    dispatch(actions.setCollapseCampaignRow(status));
  };
};
