import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const FullWidthContainer = styled.div`
  width: 100%;
  margin: 8px;
`;

const StyledTextField = withStyles(theme => ({
  root: {
    margin: 8
  }
}))(TextField);

const SubTitle = styled.div`
  text-align: left !important;
  width: 100%;
  margin: 15px 0px 0px 8px;
`;

const StyledCheckbox = styled.div`
  text-align: left !important;
  width: 100%;
  margin: 15px 8px;
`;

const TargetLinkDiv = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: 1fr 3fr;
`;

export {
  FullWidthContainer,
  StyledCheckbox,
  SubTitle,
  StyledTextField,
  TargetLinkDiv
};
