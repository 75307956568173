import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Grid from '@material-ui/core/Grid';
import SyncIcon from '@material-ui/icons/Sync';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { withStyles } from '@material-ui/core/styles';
import fileDownload from 'js-file-download';

import AddEditCampaignForm from './AddEditCampaignForm';
import AddEditAdvertisementForm from '../Advertisement/AddEditAdvertisementForm';
import Dialog from '../Common/Dialog';
import CustomTable from '../Common/Table/index';
//Used only for login api call now -- has to be removed
import {
  API_BASE_URL,
  CAMPAIGN_DATE_FORMAT,
  MARKETING_STATUS
} from '../../constants';
import { isObjectFieldsNotEmpty } from '../../utils/validations';
import axios from 'axios';

import Toaster from '../Common/Toaster';
import {
  FormStyles,
  Container,
  FormContainer,
  StyledButton,
  StyledMarketingStatus,
  ViewHeader
} from './styles';
import { getDiffBetweenDates, convertDateToLocal } from '../../utils/dateTime';
import { descendingDateComparator } from '../../utils/sorting';
import Advertisement from '../Advertisement/container';
import { SnackbarProvider } from 'notistack';
import UserManagement from '../UserManagement';

class Campaign extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      closeForm: true,
      list: false,
      campaignList: [],
      editRow: {},
      openAddEditDialog: false,
      openAddAdvertismentAddEditDialog: false,
      formFields: {},
      advertisementFormFields: {},
      toasterMessage: '',
      showToaster: false,
      toasterType: '',
      openDeleteDialog: false,
      addAdvertisementOpenState: false,
      addAdvertisementCampaignRow: {},
      editAdvertisementCampaignRow: {},
      campaignSelected: [],
      userManagement: false
    };
  }

  componentDidMount() {
    if (this.props.login && this.props.login.loginStatus != true) {
      this.props.history.push('/view/login');
    } else {
      this.getCampaignAndDisplay();
      this.props.setCampaignCollapseRowIdStatus({ rowId: '', status: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.campaign.isListCampaignError !==
        this.props.campaign.isListCampaignError ||
      prevProps.campaign.isListCampaignSuccess !==
        this.props.campaign.isListCampaignSuccess
    ) {
      this.processListResponse();
    }

    if (
      prevProps.campaign.isdeleteCampaignError !==
        this.props.campaign.isdeleteCampaignError ||
      prevProps.campaign.isdeleteCampaignSuccess !==
        this.props.campaign.isdeleteCampaignSuccess
    ) {
      this.processDeleteResponse();
    }

    if (
      prevProps.advertisement.isAddAdversimentStatus !==
      this.props.advertisement.isAddAdversimentStatus
    ) {
      this.processAddAdvertisementSuccessResponse();
    }

    if (
      prevProps.campaign.syncCampaignStatus !=
      this.props.campaign.syncCampaignStatus
    ) {
      this.processSyncCampaignStatusResponse();
    }

    if (prevProps.login.loginStatus !== this.props.login.loginStatus) {
      if (this.props.login.loginStatus !== true) {
        this.props.history.push('/view/login');
      }
    }
  }

  processListResponse = () => {
    if (
      this.props.campaign.isListCampaignSuccess &&
      !this.props.campaign.isListCampaignError
    ) {
      this.setState({
        campaignList: this.props.campaign.campaignList,
        list: true
      });
    }
  };

  processDeleteResponse = () => {
    if (
      this.props.campaign.isdeleteCampaignSuccess &&
      !this.props.campaign.isdeleteCampaignError
    ) {
      this.setState({
        showToaster: true,
        toasterMessage: 'Successfully deleted',
        toasterType: 'success'
      });
      this.getCampaignAndDisplay();
    } else if (
      !this.props.campaign.isdeleteCampaignSuccess &&
      this.props.campaign.isdeleteCampaignError
    ) {
      this.setState({
        showToaster: true,
        toasterMessage: 'Something went wrong',
        toasterType: 'error'
      });
    }
  };

  processAddAdvertisementSuccessResponse = () => {
    if (this.props.advertisement.isAddAdversimentStatus == true) {
      this.addAdvertisementClose();
      this.getCampaignAndDisplay();
    }
  };

  processSyncCampaignStatusResponse = () => {
    if (this.props.campaign.syncCampaignStatus === true) {
      this.getCampaignAndDisplay();
      this.setState({
        campaignSelected: [],
        showToaster: true,
        toasterMessage: 'Sync Successfull',
        toasterType: 'success',
        campaignSelected: []
      });
    }
    if (this.props.campaign.syncCampaignStatus === false) {
      {
        this.setState({
          showToaster: true,
          toasterMessage: 'Something went wrong',
          toasterType: 'error',
          campaignSelected: []
        });
      }
    }
  };

  setFormFields = fields => {
    this.setState({
      formFields: fields
    });
  };

  setAdvertisementFormFields = fields => {
    this.setState({
      advertisementFormFields: fields
    });
  };

  changeAddCampaignFormCloseStatus = type => {
    if (
      type === 'back' &&
      isObjectFieldsNotEmpty(this.state.formFields, {
        filter: 'campaignId'
      }) === true
    ) {
      this.setState({
        openAddEditDialog: true
      });
    } else {
      this.setState({
        closeForm: true,
        list: false,
        editRow: {},
        openAddEditDialog: false,
        formFields: {}
      });
      this.getCampaignAndDisplay();
    }
  };

  changeAddCampaignFormOpenStatus = () => {
    this.setState({
      closeForm: false,
      list: false
    });
  };

  handleConformarionDialogOkay = () => {
    this.setState({
      openAddEditDialog: false,
      openAddAdvertismentAddEditDialog: false
    });
  };

  getCampaignAndDisplay = () => {
    this.props.listCampaigns();
  };

  handleCampaignListRowEdit = row => {
    this.setState({
      editRow: row,
      closeForm: false,
      list: false
    });
  };

  handleCampaignDelete = row => {
    this.setState({
      openDeleteDialog: true,
      deleteId: row.id
    });
  };

  handleDeleteDialogClose = () => {
    this.setState({
      openDeleteDialog: false
    });
  };

  handleDeleteDialogOkay = () => {
    this.props.deleteCampaign(this.state.deleteId);
    this.setState({
      openDeleteDialog: false
    });
  };

  handleToasterClose = () => {
    this.setState({
      showToaster: false
    });
  };

  addAdvertisementOpen = campaignRow => {
    this.setState({
      list: false,
      addAdvertisementOpenState: true,
      addAdvertisementCampaignRow: campaignRow
    });
  };

  addAdvertisementOpen = campaignRow => {
    this.setState({
      list: false,
      addAdvertisementOpenState: true,
      addAdvertisementCampaignRow: campaignRow
    });
  };

  handleAdvertisementEdit = (data, campaignRow) => {
    this.setState({
      list: false,
      addAdvertisementOpenState: true,
      editAdvertisementCampaignRow: data,
      addAdvertisementCampaignRow: campaignRow
    });
  };

  addAdvertisementClose = type => {
    if (
      type === 'back' &&
      isObjectFieldsNotEmpty(this.state.advertisementFormFields, {
        filter: 'advertisementId'
      }) === true
    ) {
      this.setState({
        openAddAdvertismentAddEditDialog: true
      });
    } else {
      this.setState({
        list: true,
        addAdvertisementOpenState: false,
        addAdvertisementCampaignRow: {},
        editAdvertisementCampaignRow: {},
        advertisementFormFields: {},
        openAddAdvertismentAddEditDialog: false
      });
    }
  };

  setCampaignSelected = selected => {
    this.setState({
      campaignSelected: selected
    });
  };

  renderAddCampaignButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<AddIcon />}
        onClick={this.changeAddCampaignFormOpenStatus}
        id="add_cmp_btn"
      >
        Add Campaign
      </StyledButton>
    );
  }

  getCollapseDisplayData = row => {
    return (
      <Advertisement
        campaignRow={row}
        deleteAdvertisement={this.props.deleteAdvertisement}
        reloadAdvertisment={this.changeAddCampaignFormCloseStatus}
        handleAdvertisementEdit={this.handleAdvertisementEdit}
        addAdvertisementOpen={this.addAdvertisementOpen}
        advertisements={row.advertisements || []}
        userType={this.props.login.userType}
      />
    );
  };

  handleExport = async () => {
    const campaigns = await this.props.exportCampaigns();
    fileDownload(JSON.stringify(campaigns), 'campaigns.json');
  };

  handleSync = () => {
    const { campaignSelected } = this.state;
    this.props.syncCampaigns(campaignSelected);
  };

  openUserManagement = () => {
    this.props.history.push('/view/user');
  };

  renderExportButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<ImportExportIcon />}
        id="export_btn"
        onClick={this.handleExport}
      >
        Export
      </StyledButton>
    );
  }

  renderSyncCampaignsButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<SyncIcon />}
        id="sync_btn"
        onClick={this.handleSync}
      >
        Sync
      </StyledButton>
    );
  }

  renderListCampaignButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<AddIcon />}
        onClick={this.getCampaignAndDisplay}
        id="list_btn"
      >
        List Campaign
      </StyledButton>
    );
  }

  renderLogoutButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        startIcon={<ExitToAppIcon />}
        onClick={this.props.dispatchLogout}
        id="logout"
      >
        Logout
      </StyledButton>
    );
  }

  renderUserManagementButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        startIcon={<PersonAddIcon />}
        onClick={this.openUserManagement}
        id="logout"
      >
        User Management
      </StyledButton>
    );
  }

  setCollapseRowIdStatus = statusObj => {
    this.props.setCampaignCollapseRowIdStatus(statusObj);
  };

  render = () => {
    const {
      addNewCampaign,
      campaign,
      advertisement,
      editCampaign,
      addNewAdvertisement,
      editAdvertisement,
      advertisementConfig,
      classes,
      login
    } = this.props;

    const {
      campaignList,
      editRow,
      showToaster,
      toasterType,
      toasterMessage,
      addAdvertisementCampaignRow,
      editAdvertisementCampaignRow,
      campaignSelected
    } = this.state;

    const headerCells = [
      { id: 'campaignId', type: 'text', label: 'Campaign Id', sortable: false },
      { id: 'campaignName', type: 'text', label: 'Name', sortable: false },
      {
        id: 'startDate',
        type: 'date',
        label: 'Start Date',
        sortable: true,
        processor: displayStartDate
      },
      {
        id: 'endDate',
        type: 'date',
        label: 'End Date',
        sortable: true,
        processor: displayEndDate
      },
      { id: 'provider', type: 'text', label: 'Provider', sortable: false },
      {
        id: 'marketingStatus',
        type: 'text',
        label: 'Marketing Status',
        sortable: false,
        processor: displayMarketingStatus
      },
      {
        id: 'configStatusForDisplay',
        type: 'text',
        label: 'Configuration Status',
        sortable: false,
        processor: displayConfigStatus
      }
    ];

    const features = {
      enableCheckbox: login.userType === 'read' ? false : true,
      enableActions: login.userType === 'read' ? false : true,
      enableCollapse: true,
      collapseRowData: this.getCollapseDisplayData,
      deleteActionHandler: this.handleCampaignDelete,
      editActionHandler: this.handleCampaignListRowEdit,
      defaultSortColumn: 'startDate',
      defaultSortOrder: 'desc',
      enableSelectedCollapseStatus: true
    };
    return (
      <Container>
        <Toaster
          message={toasterMessage}
          type={toasterType}
          open={showToaster}
          handleClose={this.handleToasterClose}
        />
        {this.state.closeForm && !this.state.addAdvertisementOpenState ? (
          <div>
            <Grid container spacing={0}>
              <Grid item xs={6} align="left">
                <ViewHeader>Campaign list</ViewHeader>
              </Grid>
              <Grid item xs={6} align="right">
                {login.userType === 'admin'
                  ? this.renderUserManagementButton()
                  : null}
                {this.renderLogoutButton()}
              </Grid>
            </Grid>
            {login.userType !== 'read' ? (
              <Grid container spacing={0}>
                <Grid item xs={6} align="left">
                  <Grid container spacing={0}>
                    <Grid item xs={0} align="left">
                      {this.renderSyncCampaignsButton()}
                    </Grid>
                    <Grid item xs={0} align="left">
                      {this.renderAddCampaignButton()}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} align="right">
                  {this.renderExportButton()}
                </Grid>
              </Grid>
            ) : null}
          </div>
        ) : null}
        {!this.state.closeForm ? (
          <FormContainer>
            <div
              className="right"
              onClick={() => this.changeAddCampaignFormCloseStatus('back')}
              id="add_cmp_close_btn"
            >
              <CloseIcon />
            </div>
            <AddEditCampaignForm
              addNewCampaign={addNewCampaign}
              editCampaign={editCampaign}
              campaign={campaign}
              changeAddCampaignFormCloseStatus={
                this.changeAddCampaignFormCloseStatus
              }
              editRow={editRow}
              setFormFields={this.setFormFields}
            />
          </FormContainer>
        ) : null}
        {this.state.addAdvertisementOpenState ? (
          <FormContainer>
            <div
              className="right"
              onClick={() => this.addAdvertisementClose('back')}
              id="add_ad_close_btn"
            >
              <CloseIcon />
            </div>
            <SnackbarProvider
              hideIconVariant
              classes={{
                variantError: classes.error
              }}
            >
              <AddEditAdvertisementForm
                campaignRow={addAdvertisementCampaignRow}
                editRow={editAdvertisementCampaignRow}
                setFormFields={this.setAdvertisementFormFields}
                addNewAdvertisement={addNewAdvertisement}
                editAdvertisement={editAdvertisement}
                advertisementConfig={advertisementConfig}
                advertisement={advertisement}
              />
            </SnackbarProvider>
          </FormContainer>
        ) : null}
        {this.state.list ? (
          <CustomTable
            list={campaignList}
            headCells={headerCells}
            features={features}
            setCheckboxSelected={this.setCampaignSelected}
            rowSelected={campaignSelected || []}
            id="campaign_list"
            setCollapseRowIdStatus={this.setCollapseRowIdStatus}
            collapsedRow={campaign.collapsedRow}
          />
        ) : null}
        {this.state.openAddEditDialog ? (
          <Dialog
            open={this.state.openAddEditDialog}
            message="You have made some changes to the fields. Do you want to save it?"
            handleClose={this.changeAddCampaignFormCloseStatus}
            handleOkay={this.handleConformarionDialogOkay}
            closeText="close"
            okayText="ok"
          />
        ) : null}
        {this.state.openDeleteDialog ? (
          <Dialog
            open={this.state.openDeleteDialog}
            message="Are you sure you want to delete the campaign?"
            handleClose={this.handleDeleteDialogClose}
            handleOkay={this.handleDeleteDialogOkay}
            closeText="no"
            okayText="yes"
          />
        ) : null}
        {this.state.openAddAdvertismentAddEditDialog ? (
          <Dialog
            open={this.state.openAddAdvertismentAddEditDialog}
            message="You have made some changes to the fields. Do you want to save it?"
            handleClose={this.addAdvertisementClose}
            handleOkay={this.handleConformarionDialogOkay}
            closeText="close"
            okayText="ok"
          />
        ) : null}
      </Container>
    );
  };
}

const displayConfigStatus = row => `${row.configStatus}
${getDiffBetweenDates(row.updatedAt)} ago`;

const displayMarketingStatus = row => {
  var todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  if (descendingDateComparator(row.startDate, todayDate) === -1) return '';

  let status =
    descendingDateComparator(todayDate, row.endDate) === -1
      ? MARKETING_STATUS.EXPIRED
      : MARKETING_STATUS.ONGOING;

  return (
    <StyledMarketingStatus status={status}>{status}</StyledMarketingStatus>
  );
};

const displayStartDate = row =>
  convertDateToLocal(row.startDate, CAMPAIGN_DATE_FORMAT);

const displayEndDate = row =>
  convertDateToLocal(row.endDate, CAMPAIGN_DATE_FORMAT);

export default withStyles(FormStyles)(Campaign);
