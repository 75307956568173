import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ActionTableCell, StyledTableHead } from './styles';

export default function CustomTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    features
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {features.enableCheckbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        ) : null}

        {headCells.map(headCell =>
          !headCell.hidden ? (
            <TableCell
              key={headCell.id}
              align={headCell.type === 'numeric' ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {!headCell.sortable ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ) : null
        )}
        {features.enableEnabledCheckbox ? (
          <ActionTableCell>Active</ActionTableCell>
        ) : null}
        {features.enableActions ? (
          <ActionTableCell>Actions</ActionTableCell>
        ) : null}
        {features.enableSelectBoxColumn
          ? features.numberOfSelectBoxColumn.map(
              (value: undefined, index: number) => (
                <TableCell
                  key={
                    features.selectBoxAttributes[index] &&
                    features.selectBoxAttributes[index].elementId
                  }
                  align="left"
                >
                  <TableSortLabel>
                    {features.selectBoxAttributes[index] &&
                      features.selectBoxAttributes[index].label}
                  </TableSortLabel>
                </TableCell>
              )
            )
          : null}
      </TableRow>
    </StyledTableHead>
  );
}

CustomTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array
};
