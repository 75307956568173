import axios from 'axios';
import { MOBILE_API_BASE_URL, API_BASE_URL } from '../constants';
import {
  MOVIE_CATEGORY,
  LIFESTYLE_CATEGORY,
  KIDS_CATEGORY,
  TV_SHOW_CATEGORY,
  MOVIES_CATEGORY_LIST
} from '../constants/advertisement';
import { get } from 'lodash';

export const removeWhiteSpaces = obj => {
  for (let i in obj) {
    if (typeof obj[i] === 'string') {
      obj[i] = obj[i].trim();
    }
  }
  return obj;
};

export const changeAgeFieldsIfEmpty = obj => {
  if (obj.minAge === '') {
    obj.minAge = 0;
  }
  if (obj.maxAge === '') {
    obj.maxAge = 0;
  }
  return obj;
};

export const filterUniqueContentById = array => {
  const temp = [];
  const output = [];

  for (let i = 0; i < array.length; i++) {
    if (temp[array[i].code]) continue;
    temp[array[i].code] = true;
    output.push(array[i]);
  }

  return output;
};

export const extractNameKeyFromArrayJson = array => {
  let result = [];
  array.forEach(obj => {
    result.push(obj.name.toString());
  });

  return result;
};

const getHeaders = airline => {
  const token = localStorage.getItem('token');
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/content/app/header?airline=${airline}`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          resolve([]);
        }
      })
      .catch(function(err) {
        console.log('[getHeaders] Exception : [Header Api] : ', err);
        reject(err);
      });
  });
};

const checkVideoTag = (video, tag) => {
  return video.tags && video.tags.includes(tag);
};

const checkVideoGroup = (video, groupId) => {
  return video.groupId === groupId;
};

const filterByListOfCategories = (data, list, parentCategory) => {
  let result = [];
  for (const category of list) {
    if (parentCategory === MOVIE_CATEGORY) {
      const videos = data.filter(video => checkVideoTag(video, category));
      result = [...result, ...videos];
    }
    if (parentCategory === LIFESTYLE_CATEGORY) {
      const videos = data.filter(video => checkVideoGroup(video, category));
      result = [...result, ...videos];
    }
  }
  return result;
};

async function filterVideosByCategory(data, category, airline) {
  if (category === MOVIE_CATEGORY) {
    return filterByListOfCategories(data, MOVIES_CATEGORY_LIST, MOVIE_CATEGORY);
  } else if (category === LIFESTYLE_CATEGORY) {
    try {
      const result = await getHeaders(airline);
      const lifestyleCategories = result.filter(cat =>
        checkVideoTag(cat, LIFESTYLE_CATEGORY)
      );
      const categoryList = lifestyleCategories.reduce((result, cat) => {
        return [...result, cat.groupId[0]];
      }, []);
      return filterByListOfCategories(data, categoryList, LIFESTYLE_CATEGORY);
    } catch (e) {
      console.log('[filterVideosByCategory] Exception : [Header Api] : ', e);
      return [];
    }
  } else {
    const headers = await getHeaders(airline);
    const filterCategory =
      category === TV_SHOW_CATEGORY ? 'tvshow_catalog' : category;
    const categories = headers.filter(cat =>
      checkVideoTag(cat, filterCategory)
    );
    let result = [];
    categories.forEach(function(item, index) {
      const groupId =
        typeof item.groupId === 'object' ? item.groupId[0] : item.groupId;
      let groupData = groupId
        ? data.filter(vid => vid.groupId === groupId) || []
        : [];
      result = [...result, ...groupData];
    });
    if (category === KIDS_CATEGORY) {
      return [
        ...result,
        ...data.filter(video => checkVideoTag(video, category))
      ];
    }
    return result;
  }
}

const converToOptions = data => {
  const result = [];
  data.forEach(value => {
    result.push(value.id);
  });
  return result;
};

export const getContentIdsByCategory = (categories, airline) => {
  const token = localStorage.getItem('token');
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/content/app/video?airline=${airline}`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          let promiseArray = [];
          categories.forEach((category, index) => {
            promiseArray.push(
              filterVideosByCategory(response.data, category, airline)
            );
          });
          Promise.all(promiseArray).then(result => {
            resolve(converToOptions(result.flat()));
          });
        } else {
          resolve([]);
        }
      })
      .catch(function(err) {
        console.log(
          '[getContentIdsByCategory] Exception : [Video Api] : ',
          err
        );
        resolve([]);
      });
  });
};
