import { handleActions } from 'redux-actions';
import * as actionTypes from '../constants/actionTypes';
import update from 'immutability-helper';

const initalState = {
  isAddCampaignError: false,
  isAddCampaignSuccess: false,
  isListCampaignError: false,
  isListCampaignSuccess: false,
  isdeleteCampaignError: false,
  isdeleteCampaignSuccess: false,
  campaignList: [],
  syncCampaignStatus: 'init',
  collapsedRow: { rowId: '', status: false }
};

const addCampaignStart = (state, action) =>
  update(state, {
    isAddCampaignError: { $set: false },
    isAddCampaignSuccess: { $set: false }
  });

const addCampaignSuccess = (state, action) =>
  update(state, {
    isAddCampaignError: { $set: false },
    isAddCampaignSuccess: { $set: true }
  });

const addCampaignError = (state, action) =>
  update(state, {
    isAddCampaignError: { $set: true },
    isAddCampaignSuccess: { $set: false }
  });

const listCampaignStart = (state, action) =>
  update(state, {
    isListCampaignError: { $set: false },
    campaignList: { $set: [] },
    isListCampaignSuccess: { $set: false }
  });

const listCampaignSuccess = (state, action) =>
  update(state, {
    isListCampaignError: { $set: false },
    campaignList: { $set: action.payload },
    isListCampaignSuccess: { $set: true }
  });

const listCampaignError = (state, action) =>
  update(state, {
    isListCampaignError: { $set: true },
    isListCampaignSuccess: { $set: false }
  });

const deleteCampaignStart = (state, action) =>
  update(state, {
    isdeleteCampaignError: { $set: false },
    isdeleteCampaignSuccess: { $set: false }
  });

const deleteCampaignSuccess = (state, action) =>
  update(state, {
    isdeleteCampaignError: { $set: false },
    isdeleteCampaignSuccess: { $set: true }
  });

const deleteCampaignError = (state, action) =>
  update(state, {
    isdeleteCampaignError: { $set: true },
    isdeleteCampaignSuccess: { $set: false }
  });

const syncCampaignStart = (state, action) =>
  update(state, {
    syncCampaignStatus: { $set: 'init' }
  });

const syncCampaignSuccess = (state, action) =>
  update(state, {
    syncCampaignStatus: { $set: true }
  });

const syncCampaignError = (state, action) =>
  update(state, {
    syncCampaignStatus: { $set: false }
  });

const setCampaignCollapseRow = (state, action) =>
  update(state, {
    collapsedRow: { $set: action.payload }
  });

export default handleActions(
  {
    [actionTypes.ADD_CAMPAIGN_REQUEST_START]: addCampaignStart,
    [actionTypes.ADD_CAMPAIGN_SUCCESS]: addCampaignSuccess,
    [actionTypes.ADD_CAMPAIGN_ERROR]: addCampaignError,
    [actionTypes.LIST_CAMPAIGN_REQUEST_START]: listCampaignStart,
    [actionTypes.LIST_CAMPAIGN_SUCCESS]: listCampaignSuccess,
    [actionTypes.LIST_CAMPAIGN_ERROR]: listCampaignError,
    [actionTypes.DELETE_CAMPAIGN_REQUEST_START]: deleteCampaignStart,
    [actionTypes.DELETE_CAMPAIGN_SUCCESS]: deleteCampaignSuccess,
    [actionTypes.DELETE_CAMPAIGN_ERROR]: deleteCampaignError,
    [actionTypes.SYNC_CAMPAIGN_REQUEST_START]: syncCampaignStart,
    [actionTypes.SYNC_CAMPAIGN_SUCCESS]: syncCampaignSuccess,
    [actionTypes.SYNC_CAMPAIGN_ERROR]: syncCampaignError,
    [actionTypes.SET_CAMPAIGN_COLLAPSE_ROW]: setCampaignCollapseRow
  },
  initalState
);
