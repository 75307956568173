import update from 'immutability-helper';

const setOccupiedValuesForLeaderboardType = (advertisement, commonObject) => {
  return update(commonObject, {
    placementIndex: {
      $set:
        (advertisement.leaderboard &&
          advertisement.leaderboard.placementIndex) ||
        null
    }
  });
};

const setOccupiedValuesForFooterType = (advertisement, commonObject) => {
  return update(commonObject, {
    pagesToInclude: {
      $set: (advertisement.footer && advertisement.footer.pageToInclude) || null
    },
    airlineAllocation: {
      $set: advertisement.airlineAllocation || []
    }
  });
};

const setOccupiedValuesForPopupType = (advertisement, commonObject) => {
  return update(commonObject, {
    pagesToInclude: {
      $set: (advertisement.popUp && advertisement.popUp.pageToInclude) || null
    },
    airlineAllocation: {
      $set: advertisement.airlineAllocation || []
    }
  });
};

const setOccupiedValuesForPrerollType = (advertisement, commonObject) => {
  return update(commonObject, {
    videosToInclude: {
      $set:
        (advertisement.preroll && advertisement.preroll.videosToInclude) || null
    },
    airlineAllocation: {
      $set: advertisement.airlineAllocation || []
    },
    placementIndex: {
      $set:
        (advertisement.preroll && advertisement.preroll.placementIndex) || null
    }
  });
};

const setOccupiedValuesForMidrollType = (advertisement, commonObject) => {
  return update(commonObject, {
    videosToInclude: {
      $set:
        (advertisement.midroll && advertisement.midroll.videosToInclude) || null
    },
    airlineAllocation: {
      $set: advertisement.airlineAllocation || []
    },
    placementIndex: {
      $set:
        (advertisement.midroll && advertisement.midroll.placementIndex) || null
    }
  });
};

export const setOccupiedValuesForAdvertisementType = (
  campaign,
  advertisement,
  alreadyOccupiedAdvertismentValues
) => {
  let commonObject = {
    campaignName: campaign.campaignName,
    advertisementName: advertisement.advertisementName,
    startDate: campaign.startDate,
    endDate: campaign.endDate
  };

  let thisObj = {};

  if (advertisement.advertisementType === 'leaderboard') {
    thisObj = setOccupiedValuesForLeaderboardType(advertisement, commonObject);
  }
  if (advertisement.advertisementType === 'footer') {
    thisObj = setOccupiedValuesForFooterType(advertisement, commonObject);
  }
  if (advertisement.advertisementType === 'popUp') {
    thisObj = setOccupiedValuesForPopupType(advertisement, commonObject);
  }
  if (advertisement.advertisementType === 'preroll') {
    thisObj = setOccupiedValuesForPrerollType(advertisement, commonObject);
  }
  if (advertisement.advertisementType === 'midroll') {
    thisObj = setOccupiedValuesForMidrollType(advertisement, commonObject);
  }
  if (!alreadyOccupiedAdvertismentValues[advertisement.advertisementType]) {
    alreadyOccupiedAdvertismentValues[advertisement.advertisementType] = [];
  }
  alreadyOccupiedAdvertismentValues[advertisement.advertisementType].push(
    thisObj
  );
};
