import { handleActions } from 'redux-actions';
import * as actionTypes from '../constants/actionTypes';
import update from 'immutability-helper';

const initalState = {
  loginStatus: 'init',
  userType: 'read'
};

const loginStart = (state, action) =>
  update(state, {
    loginStatus: { $set: 'init' }
  });

const loginSuccess = (state, action) =>
  update(state, {
    loginStatus: { $set: true },
    userType: { $set: action.payload }
  });

const loginError = (state, action) =>
  update(state, {
    loginStatus: { $set: false }
  });

const logout = (state, action) =>
  update(state, {
    loginStatus: { $set: 'init' },
    campaignList: { $set: [] },
    collapsedRow: { $set: {} },
    fetchedAdvertisementData: { $set: {} },
    alreadyOccupiedAdvertismentValues: { $set: {} }
  });

export default handleActions(
  {
    [actionTypes.LOGIN_REQUEST_START]: loginStart,
    [actionTypes.LOGIN_REQUEST_SUCCESS]: loginSuccess,
    [actionTypes.LOGIN_REQUEST_ERROR]: loginError,
    [actionTypes.LOGOUT_REQUEST]: logout
  },
  initalState
);
