import React from 'react';
import { FullWidthContainer, StyledTextField, StyledCheckbox } from './styles';
import Multiselect from '../Common/Multiselect';
import { PAGES_TO_INCLUDE } from '../../constants';
import Checkbox from '@material-ui/core/Checkbox';

class Popup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      skipVideoAdCheck: props.fields.skipVideoAdvertisement || false
    };
  }

  handleVideoAdCheck = () => {
    this.handleInputChange(
      'skipVideoAdvertisement',
      !this.state.skipVideoAdCheck
    );
    this.setState({
      skipVideoAdCheck: !this.state.skipVideoAdCheck
    });
  };

  handleInputChange = (id, value) => {
    this.props.handleChange({
      target: {
        type: 'popUp',
        id: id,
        value: value
      }
    });
  };

  render() {
    const { fields } = this.props;
    const { skipVideoAdCheck } = this.state;
    return (
      <div>
        <StyledTextField
          fullWidth
          id="targetLinkOnline"
          label="Target Link (Online) "
          margin="normal"
          onChange={e => this.handleInputChange(e.target.id, e.target.value)}
          value={fields.targetLinkOnline}
          variant="outlined"
        />
        <StyledTextField
          fullWidth
          id="targetLinkOffline"
          label="Target Link (Offline)"
          margin="normal"
          onChange={e => this.handleInputChange(e.target.id, e.target.value)}
          value={fields.targetLinkOffline}
          variant="outlined"
        />
        <StyledCheckbox>
          Allow user to skip Video advertisement:
          <Checkbox
            checked={skipVideoAdCheck}
            onChange={this.handleVideoAdCheck}
            name="checkedB"
            color="primary"
          />
        </StyledCheckbox>
        <FullWidthContainer>
          <Multiselect
            labelId="pageToIncludeLabel"
            label="Pages to Include"
            elementId="pageToInclude"
            options={PAGES_TO_INCLUDE}
            multiple={true}
            setValue={this.handleInputChange}
            defaultValue={fields.pageToInclude || []}
            error={this.props.error.pageToInclude || false}
          />
        </FullWidthContainer>
      </div>
    );
  }
}

export default Popup;
