import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserManagement from './UserManagement';
import {
  getUserRolesAction,
  addUserAction,
  listUsersAction,
  updateUserAction
} from '../../actions/userAction';
import { logout } from '../../actions/loginAction';

const mapStateToProps = state => {
  const { user, login } = state;
  return {
    user,
    login
  };
};

const mapDispatchToProps = dispatch => ({
  getUserRoles() {
    dispatch(getUserRolesAction());
  },
  addUser(data) {
    dispatch(addUserAction(data));
  },
  listUsers() {
    dispatch(listUsersAction());
  },
  updateUser(id, data) {
    dispatch(updateUserAction(id, data));
  },
  dispatchLogout() {
    dispatch(logout());
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserManagement)
);
