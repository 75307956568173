import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import React from 'react';

class Toaster extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      message,
      type,
      open,
      handleClose,
      vertical,
      horizontal
    } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: vertical || 'top',
            horizontal: horizontal || 'center'
          }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity={type}>{message}</Alert>
        </Snackbar>
      </div>
    );
  }
}

export default Toaster;
