import { handleActions } from 'redux-actions';
import * as actionTypes from '../constants/actionTypes';
import update from 'immutability-helper';

const initalState = {
  roles: [],
  addUserStatus: 'init',
  listUsersStatus: 'init',
  users: []
};

const userRoleStart = (state, action) =>
  update(state, {
    roles: { $set: [] }
  });

const userRoleSuccess = (state, action) =>
  update(state, {
    roles: { $set: action.payload }
  });

const userRoleError = (state, action) =>
  update(state, {
    roles: { $set: [] }
  });

const addUserStart = (state, action) =>
  update(state, {
    addUserStatus: { $set: 'init' }
  });

const addUserSuccess = (state, action) =>
  update(state, {
    addUserStatus: { $set: true }
  });

const addUserError = (state, action) =>
  update(state, {
    addUserStatus: { $set: false }
  });

const listUsersStart = (state, action) =>
  update(state, {
    listUsersStatus: { $set: 'init' },
    users: { $set: [] }
  });

const listUsersSuccess = (state, action) =>
  update(state, {
    listUsersStatus: { $set: true },
    users: { $set: action.payload }
  });

const listUsersError = (state, action) =>
  update(state, {
    listUsersStatus: { $set: false },
    users: { $set: [] }
  });

export default handleActions(
  {
    [actionTypes.USER_ROLE_START]: userRoleStart,
    [actionTypes.USER_ROLE_SUCCESS]: userRoleSuccess,
    [actionTypes.USER_ROLE_ERROR]: userRoleError,
    [actionTypes.ADD_USER_START]: addUserStart,
    [actionTypes.ADD_USER_SUCCESS]: addUserSuccess,
    [actionTypes.ADD_USER_ERROR]: addUserError,
    [actionTypes.LIST_USERS_START]: listUsersStart,
    [actionTypes.LIST_USERS_SUCCESS]: listUsersSuccess,
    [actionTypes.LIST_USERS_ERROR]: listUsersError
  },
  initalState
);
