import { format, formatDistance } from 'date-fns';
import moment from 'moment';

const changeDateTimeStringToDate = str => {
  let thisDate = new Date(str);
  thisDate.setHours(0, 0, 0, 0);

  return thisDate;
};

export const greaterThanDateComparator = (a, b) => {
  a = changeDateTimeStringToDate(a);
  b = changeDateTimeStringToDate(b);

  if (a > b) return true;

  return false;
};

export const convertDateToLocal = (date, dateFormat = 'yyyy-MM-dd') =>
  format(new Date(date), dateFormat);

export const getDiffBetweenDates = (d1, d2 = new Date()) =>
  formatDistance(new Date(d2), new Date(d1), {
    includeSeconds: true
  });

const getDaysDiff = (d1, d2) => {
  var timeDiff = d2.getTime() - d1.getTime();
  return timeDiff / (1000 * 3600 * 24);
};

export const checkDateOutOfRange = (
  startDate,
  endDate,
  startDateToCheckFrom,
  endDateToCheckFrom
) => {
  let thisStartD = changeDateTimeStringToDate(startDate);
  let thisEndD = changeDateTimeStringToDate(endDate);
  let thisStartDateToCheckFrom = changeDateTimeStringToDate(
    startDateToCheckFrom
  );
  let thisEndDateToCheckFrom = changeDateTimeStringToDate(endDateToCheckFrom);

  if (
    thisStartD > thisEndDateToCheckFrom ||
    thisEndD < thisStartDateToCheckFrom
  ) {
    return { status: true, data: {} };
  }

  if (
    thisStartD > thisStartDateToCheckFrom &&
    thisStartD < thisEndDateToCheckFrom &&
    thisEndD > thisEndDateToCheckFrom
  ) {
    return {
      status: false,
      data: {
        type: 'startDate',
        diff: getDaysDiff(thisStartD, thisEndDateToCheckFrom)
      }
    };
  }

  if (
    thisEndD > thisStartDateToCheckFrom &&
    thisEndD < thisEndDateToCheckFrom &&
    thisStartD < thisStartDateToCheckFrom
  ) {
    return {
      status: false,
      data: {
        type: 'endDate',
        diff: getDaysDiff(thisStartDateToCheckFrom, thisEndD)
      }
    };
  }
  return { status: false, data: { type: 'both' } };
};

export const convertToIsoWithZeroTime = value => {
  return moment(value).format('YYYY-MM-DDT00:00:00.000') + 'Z';
};
