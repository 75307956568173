import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { useStyles } from './styles';

function NumberFormatCustom(props) {
  const { inputRef, onChange, min, max, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onChange={e => {
        onChange({
          target: {
            id: props.id,
            value: e.target.value
          }
        });
      }}
      allowNegative={false}
      isAllowed={values => {
        if (values.floatValue) {
          const { floatValue } = values;
          return floatValue >= 0 && floatValue <= 150;
        } else {
          return true;
        }
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function FormattedInputs(props) {
  const [values, setValues] = React.useState({});
  const { label, name, id } = props;

  useEffect(() => {
    let valueObj = {};
    valueObj[props.id] = props.defaultValue;
    setValues(valueObj);
  }, [props]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.id]: event.target.value
    });
    props.handleChange(event);
  };

  return (
    <TextField
      label={label}
      name={label}
      value={values[props.id]}
      onChange={handleChange}
      name={name}
      id={id}
      variant="outlined"
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
    />
  );
}
