import { handleActions } from 'redux-actions';
import * as actionTypes from '../constants/actionTypes';
import update from 'immutability-helper';

const initalState = {
  isAddAdversimentStatus: 'init',
  getAdvertisementStatus: 'init',
  fetchedAdvertisementData: {},
  deleteAdvertisementStatus: 'init',
  alreadyOccupiedAdvertismentValues: {}
};

const isAddAdversimentStart = (state, action) =>
  update(state, {
    isAddAdversimentStatus: { $set: 'init' }
  });

const isAddAdversimentSuccess = (state, action) =>
  update(state, {
    isAddAdversimentStatus: { $set: true }
  });

const isAddAdversimentError = (state, action) =>
  update(state, {
    isAddAdversimentStatus: { $set: false }
  });

const isGetAdversimentStart = (state, action) =>
  update(state, {
    getAdvertisementStatus: { $set: 'init' },
    fetchedAdvertisementData: { $set: {} }
  });

const isGetAdversimentSuccess = (state, action) =>
  update(state, {
    getAdvertisementStatus: { $set: true },
    fetchedAdvertisementData: { $set: action.payload }
  });

const isGetAdversimentError = (state, action) =>
  update(state, {
    getAdvertisementStatus: { $set: false },
    fetchedAdvertisementData: { $set: {} }
  });

const deleteAdvertisementStart = state =>
  update(state, {
    deleteAdvertisementStatus: { $set: 'init' }
  });

const deleteAdvertisementSuccess = state =>
  update(state, {
    deleteAdvertisementStatus: { $set: 'success' }
  });

const deleteAdvertisementError = state =>
  update(state, {
    deleteAdvertisementStatus: { $set: 'error' }
  });

const setAlreadyOccupiedAdvertismentValues = (state, action) =>
  update(state, {
    alreadyOccupiedAdvertismentValues: { $set: action.payload }
  });

export default handleActions(
  {
    [actionTypes.ADD_ADVERTISEMENT_REQUEST_START]: isAddAdversimentStart,
    [actionTypes.ADD_ADVERTISEMENT_SUCCESS]: isAddAdversimentSuccess,
    [actionTypes.ADD_ADVERTISEMENT_ERROR]: isAddAdversimentError,
    [actionTypes.GET_ADVERTISEMENT_START]: isGetAdversimentStart,
    [actionTypes.GET_ADVERTISEMENT_SUCCESS]: isGetAdversimentSuccess,
    [actionTypes.GET_ADVERTISEMENT_ERROR]: isGetAdversimentError,
    [actionTypes.DELETE_ADVERTISEMENT_REQUEST_START]: deleteAdvertisementStart,
    [actionTypes.DELETE_ADVERTISEMENT_SUCCESS]: deleteAdvertisementSuccess,
    [actionTypes.DELETE_ADVERTISEMENT_ERROR]: deleteAdvertisementError,
    [actionTypes.SET_ALREADY_OCCUPIED_ADVERTISEMENT_VALUES]: setAlreadyOccupiedAdvertismentValues
  },
  initalState
);
