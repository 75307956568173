import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Advertisement from './index';
import {
  getAdvertisementById,
  getAdvertismentConfigData,
  getAdvertismentVideoContent,
  getContentData
} from '../../actions/advertisementAction';

const mapStateToProps = state => {
  const { advertisement, advertisementConfig } = state;
  return {
    advertisement,
    advertisementConfig
  };
};

const mapDispatchToProps = {
  getAdvertisementById,
  getAdvertismentConfigData,
  getAdvertismentVideoContent,
  getContentData
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Advertisement)
);
