import React, { useState, useEffect } from 'react';
import { FullWidthContainer } from './styles';
import VideoContentDropdowns from '../Common/VideoContentDropdowns';
import Checkbox from '@material-ui/core/Checkbox';
import { SubTitle, StyledCheckbox, TargetLinkDiv } from './styles';
import { get } from 'lodash';
import TextField from '@material-ui/core/TextField';
import { filterUniqueContentById } from '../../utils/helper';
import PlacementIndexTable from '../Common/PlacementIndexTable';
import { VIDEO_CATEGORIES, PLACEMENT_INDEX } from '../../constants';
import { getContentIdsByCategory } from '../../utils/helper';

const PreRoll = ({
  fields,
  handleChange,
  videoContent,
  airlineAllocation,
  error
}) => {
  const [isChecked, setIsChecked] = useState(fields.skipAd || false);
  const [videoContentList, setVideoContentList] = useState([]);

  const [airlinesSelected, setAirlinesSelected] = useState(airlineAllocation);

  useEffect(() => {
    setAirlinesSelected(airlineAllocation);
    setVideoContentList(videoContent);
  }, [JSON.stringify(airlineAllocation), videoContent]);

  const handleCheckboxChange = () => {
    handleChange({
      target: {
        type: 'preroll',
        id: 'skipAd',
        value: !isChecked
      }
    });

    setIsChecked(!isChecked);
  };

  const handleVideoContentChange = e => {
    handleChange({
      target: {
        type: 'preroll',
        id: e.target.id,
        value: e.target.value
      }
    });
  };

  const handleInputChange = e => {
    handleChange({
      target: {
        type: 'preroll',
        id: e.target.id,
        value: e.target.value
      }
    });
  };

  const createVideoContent = (videosCategoryData, airline) => {
    const { videosToInclude } = fields;
    let thisVideoContent = videosToInclude || [];
    const airlineChangedIndex = videosCategoryData.findIndex(
      obj => obj.airline === airline
    );

    getContentIdsByCategory(
      videosCategoryData[airlineChangedIndex].value,
      videosCategoryData[airlineChangedIndex].airline
    ).then(result => {
      const objIndex = thisVideoContent.findIndex(
        obj => obj.airline == airline
      );
      const newObj = { airline, contentId: result };
      if (objIndex === -1) {
        thisVideoContent.push(newObj);
      } else {
        thisVideoContent[objIndex] = newObj;
      }
      handleVideoContentChange({
        target: {
          id: 'videosToInclude',
          value: thisVideoContent
        }
      });
    });
  };

  const handleSelectChange = (id, value, airline) => {
    if (id === 'videosCategory') {
      createVideoContent(value, airline);
    }
    handleChange({
      target: {
        type: 'preroll',
        id: id,
        value: value
      }
    });
  };

  return (
    <FullWidthContainer>
      <StyledCheckbox>
        Allow user to skip video advertisement:
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          name="checkedB"
          color="primary"
        />
      </StyledCheckbox>
      {airlinesSelected.map(function(value, index) {
        return (
          <div>
            <PlacementIndexTable
              airline={value}
              handleSelectChange={handleSelectChange}
              fields={fields}
              options={VIDEO_CATEGORIES}
              fieldName="videosCategory"
              label="Videos Category"
              error={error}
              placementIndexOptions={PLACEMENT_INDEX}
            />
          </div>
        );
      })}

      <SubTitle>Target Link (Online)</SubTitle>

      <TextField
        fullWidth
        id="targetLinkOnlineAndroid"
        label="Target Link (Install Android)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOnlineAndroid}
        variant="outlined"
      />

      <TextField
        fullWidth
        id="targetLinkOnlineIos"
        label="Target Link (Install iOS)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOnlineIos}
        variant="outlined"
      />

      <TargetLinkDiv>
        <TextField
          id="targetLinkOnlineLearnMore.label"
          label="Target Link Label"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOnlineLearnMore.label', 'Learn More')}
          variant="outlined"
        />

        <TextField
          id="targetLinkOnlineLearnMore.link"
          label="Target Link (Learn More)"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOnlineLearnMore.link', '')}
          variant="outlined"
        />
      </TargetLinkDiv>

      <SubTitle>Target Link (Offline)</SubTitle>

      <TextField
        fullWidth
        id="targetLinkOfflineAndroid"
        label="Target Link (Install Android)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOfflineAndroid}
        variant="outlined"
      />

      <TargetLinkDiv>
        <TextField
          id="targetLinkOfflineLearnMore.label"
          label="Target Link Label"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOfflineLearnMore.label', 'Learn More')}
          variant="outlined"
        />

        <TextField
          id="targetLinkOfflineLearnMore.link"
          label="Target Link (Learn More)"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOfflineLearnMore.link', '')}
          variant="outlined"
        />
      </TargetLinkDiv>
    </FullWidthContainer>
  );
};

export default PreRoll;
