import React from 'react';
import { nanoid } from 'nanoid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { StyledTextField, FormStyles } from './styles';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { isBlank, isLessDateCheck, isValidDate } from '../../utils/validations';
import { removeWhiteSpaces } from '../../utils/helper';
import { convertToIsoWithZeroTime } from '../../utils/dateTime';

import Toaster from '../Common/Toaster';
import { CONFIG_STATUS } from '../../constants';

const formFields = [
  'campaignId',
  'campaignName',
  'startDate',
  'endDate',
  'provider'
];
class AddEditCampaignForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      fields: {},
      error: {},
      dateError: false,
      showToaster: false,
      toasterType: 'error',
      toasterMessage: '',
      isEdit: false,
      validStartDateError: false,
      validEndDateError: false
    };
  }

  componentDidMount() {
    let fields = this.state.fields;
    if (isEmpty(this.props.editRow)) {
      fields.campaignId = `campaign-${nanoid(10)}`;
      fields.startDate = null;
      fields.endDate = null;
      this.setState({
        fields,
        isEdit: false
      });
    } else {
      const { editRow } = this.props;
      fields.campaignId = editRow.campaignId;
      fields.startDate = editRow.startDate;
      fields.endDate = editRow.endDate;
      fields.campaignName = editRow.campaignName;
      fields.provider = editRow.provider;
      fields.id = editRow._id;
      this.setState({
        fields,
        isEdit: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.campaign, this.props.campaign)) {
      this.processResponse();
    }
  }

  processResponse = () => {
    if (
      this.props.campaign.isAddCampaignSuccess &&
      !this.props.campaign.isAddCampaignError
    ) {
      this.props.changeAddCampaignFormCloseStatus(true);
    } else if (
      !this.props.campaign.isAddCampaignSuccess &&
      this.props.campaign.isAddCampaignError
    ) {
      this.setState({
        showToaster: true,
        toasterMessage: 'Something Went Wrong!!',
        toasterType: 'error'
      });
    }
  };

  handleInputChange = e => {
    let fields = this.state.fields;
    fields[e.target.id] = e.target.value;
    this.setState({
      fields
    });
    this.props.setFormFields(fields);
  };

  handleStartDateChange = (date, value) => {
    let fields = this.state.fields;
    let validStartDateError = false;
    if (isValidDate(date) === true) {
      fields.startDate = convertToIsoWithZeroTime(value);
    } else {
      fields.startDate = value;
      validStartDateError = true;
    }
    this.setState({
      fields,
      validStartDateError
    });
    this.props.setFormFields(fields);
  };

  handleEndDateChange = (date, value) => {
    let fields = this.state.fields;
    let validEndDateError = false;

    if (isValidDate(date) === true) {
      fields.endDate = convertToIsoWithZeroTime(value);
    } else {
      fields.endDate = value;
      validEndDateError = true;
    }
    this.setState({
      fields,
      validEndDateError
    });
    this.props.setFormFields(fields);
  };

  validateForm() {
    const { fields, validStartDateError, validEndDateError } = this.state;
    let error = [];
    let dateError = false;
    let isFormValid = true;

    for (let i in formFields) {
      if (isBlank(fields[formFields[i]])) {
        error[formFields[i]] = true;
        isFormValid = false;
      } else {
        error[formFields[i]] = false;
      }
    }

    if (!error.startDate && validStartDateError) {
      error.startDate = true;
      isFormValid = false;
    }

    if (!error.endDate && validEndDateError) {
      error.endDate = true;
      isFormValid = false;
    }

    if (
      !error.startDate &&
      !error.endDate &&
      isLessDateCheck(fields.startDate, fields.endDate)
    ) {
      error.endDate = true;
      dateError = true;
      isFormValid = false;
    }

    this.setState({
      error,
      dateError
    });

    return isFormValid;
  }

  handleFormSubmit = e => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = this.state.fields;
      fields.configStatus = CONFIG_STATUS.UPDATED;
      fields = removeWhiteSpaces(fields);
      if (this.state.isEdit) {
        this.props.editCampaign(fields);
      } else {
        this.props.addNewCampaign(fields);
      }
    }
  };

  handleToasterClose = () => {
    this.setState({
      showToaster: false
    });
  };

  render() {
    const { classes } = this.props;
    const {
      fields,
      error,
      dateError,
      toasterMessage,
      toasterType,
      showToaster,
      validStartDateError,
      validEndDateError
    } = this.state;

    return (
      <div>
        <Toaster
          message={toasterMessage}
          type={toasterType}
          open={showToaster}
          handleClose={this.handleToasterClose}
        />
        <div className={classes.heading}>{'Campaign Configuration'}</div>
        <form
          method="post"
          name="addCampaignForm"
          onSubmit={this.handleFormSubmit}
        >
          <StyledTextField
            fullWidth
            id="campaignId"
            label="Campaign Id"
            placeholder="Enter Campaign Id"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            variant="filled"
            onChange={this.handleInputChange}
            value={fields.campaignId}
            error={error.campaignId}
            helperText={error.campaignId ? 'Please Enter Campaign Id' : null}
            disabled
          />
          <StyledTextField
            fullWidth
            error={error.campaignName}
            id="campaignName"
            label="Campaign Name"
            placeholder="Enter Campaign Name"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={this.handleInputChange}
            value={fields.campaignName}
            helperText={
              error.campaignName ? 'Please Enter Campaign Name' : null
            }
          />
          <div className={classes.fullWidthDiv}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                id="startDate"
                format="yyyy/MM/dd"
                label="Start date"
                value={fields.startDate}
                onChange={this.handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                error={error.startDate}
                helperText={
                  error.startDate
                    ? validStartDateError
                      ? 'Please Enter Valid Start Date'
                      : 'Please Enter Start Campaign Date'
                    : null
                }
                className={classes.halfWidth}
              />
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                id="endDate"
                format="yyyy/MM/dd"
                label="End date"
                value={fields.endDate}
                onChange={this.handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                error={error.endDate}
                helperText={
                  error.endDate
                    ? validEndDateError
                      ? 'Please Enter Valid End date'
                      : dateError
                      ? 'End Date Should Be Greater Than Start Date'
                      : 'Please Enter End Campaign Date'
                    : null
                }
                className={classes.halfWidth}
              />
            </MuiPickersUtilsProvider>
          </div>
          <StyledTextField
            fullWidth
            error={error.provider}
            id="provider"
            label="Provider"
            placeholder="Enter Provider"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={this.handleInputChange}
            value={fields.provider}
            helperText={error.provider ? 'Please Enter Provider' : null}
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            startIcon={<SaveIcon />}
            type="submit"
            id="save_campaign_btn"
          >
            Save
          </Button>
        </form>
      </div>
    );
  }
}

export default withStyles(FormStyles)(AddEditCampaignForm);
