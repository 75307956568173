import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';

const useStyles = makeStyles(theme => ({
  numberInputBox: {
    width: '45%',
    float: 'left',
    'padding-right': '55px'
  },
  tableDiv: {
    display: 'table'
  },
  radioLabel: {
    'text-align': 'initial',
    display: 'table-cell'
  },
  radioGroup: {
    display: 'table-cell',
    'padding-left': '10px'
  }
}));

const StyledSelect = withStyles(() => ({
  root: {
    'white-space': 'unset'
  }
}))(Select);

const StyledFormControl = withStyles(() => ({
  root: {
    width: '100%',
    'text-align': 'left'
  }
}))(FormControl);

const SelectContainer = styled.div`
  margin-bottom: 8px;
`;

const SubTitle = styled.div`
  text-align: left !important;
  width: 100%;
  margin: 8px;
`;

const VideoToIncludeDiv = styled.div`
  margin-top: 15px;
`;
export {
  StyledFormControl,
  StyledSelect,
  useStyles,
  SubTitle,
  SelectContainer,
  VideoToIncludeDiv
};
