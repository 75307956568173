import { handleActions } from 'redux-actions';
import * as actionTypes from '../constants/actionTypes';
import update from 'immutability-helper';

const initalState = {
  getDataStatus: 'init',
  configData: {},
  getVideContentStatus: 'init',
  videoContent: {},
  getContentStatus: 'init',
  contentIdData: []
};

const getConfigDataStart = (state, action) =>
  update(state, {
    getDataStatus: { $set: 'init' },
    configData: { $set: {} }
  });

const getConfigDataSuccess = (state, action) =>
  update(state, {
    getDataStatus: { $set: true },
    configData: { $set: action.payload }
  });

const getConfigDataError = (state, action) =>
  update(state, {
    getDataStatus: { $set: false },
    configData: { $set: {} }
  });

const getVideoContentStart = (state, action) =>
  update(state, {
    getVideContentStatus: { $set: 'init' },
    videoContent: { $set: {} }
  });

const getVideoContentSuccess = (state, action) => {
  return update(state, {
    getVideContentStatus: { $set: 'success' },
    videoContent: { $set: action.payload }
  });
};

const getVideoContentError = (state, action) =>
  update(state, {
    getVideContentStatus: { $set: 'error' },
    videoContent: { $set: {} }
  });

const getContentDataStart = (state, action) =>
  update(state, {
    getContentStatus: { $set: 'init' },
    contentIdData: { $set: [] }
  });

const getContentDataSuccess = (state, action) =>
  update(state, {
    getContentStatus: { $set: true },
    contentIdData: { $set: action.payload }
  });

const getContentDataError = (state, action) =>
  update(state, {
    getContentStatus: { $set: false },
    contentIdData: { $set: [] }
  });

export default handleActions(
  {
    [actionTypes.GET_ADDVERTISEMENT_CONFIG_DATA_REQUEST_START]: getConfigDataStart,
    [actionTypes.GET_ADDVERTISEMENT_CONFIG_DATA_SUCCESS]: getConfigDataSuccess,
    [actionTypes.GET_ADDVERTISEMENT_CONFIG_DATA_ERROR]: getConfigDataError,
    [actionTypes.GET_VIDEO_CONTENT_REQUEST_START]: getVideoContentStart,
    [actionTypes.GET_VIDEO_CONTENT_SUCCESS]: getVideoContentSuccess,
    [actionTypes.GET_VIDEO_CONTENT_ERROR]: getVideoContentError,
    [actionTypes.GET_CONTENT_DATA_REQUEST_START]: getContentDataStart,
    [actionTypes.GET_CONTENT_DATA_SUCCESS]: getContentDataSuccess,
    [actionTypes.GET_CONTENT_DATA_ERROR]: getContentDataError
  },
  initalState
);
