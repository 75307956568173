import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useStyles } from './style';
import Toaster from '../Common/Toaster';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © dsd-ad-engine '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const userForCampaigns = ['admin', 'read', 'write'];

export default function Login(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showToaster, setShowToaster] = useState(false);

  useEffect(() => {
    if (
      props.login.loginStatus === true &&
      userForCampaigns.indexOf(props.login.userType) !== -1
    ) {
      props.history.push('/view/campaign');
    }
    if (props.login.loginStatus === false) {
      setShowToaster(true);
    }
  }, [props.login.loginStatus]);

  const handleTextChange = e => {
    if (e.target.id === 'username') {
      setUsername(e.target.value);
    }
    if (e.target.id === 'password') {
      setPassword(e.target.value);
    }
  };

  const handleFormSubmit = () => {
    props.dispatchLogin(username, password);
  };

  const handleToasterClose = () => {
    setShowToaster(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Toaster
        message={'Invalid Login Details'}
        type={'error'}
        open={showToaster}
        handleClose={handleToasterClose}
        vertical={'bottom'}
        horizontal={'center'}
      />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            onChange={handleTextChange}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleTextChange}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleFormSubmit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
