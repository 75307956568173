export const CONFIG_STATUS = {
  UPDATED: 'UPDATED',
  DEPLOYED: 'DEPLOYED'
};

export const MARKETING_STATUS = {
  EXPIRED: 'EXPIRED',
  ONGOING: 'ONGOING'
};

export const CAMPAIGN_DATE_FORMAT = 'yyyy-MM-dd';
