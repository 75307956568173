export const validationsList = {
  leaderboard: ['placementIndexCheck'],
  footer: ['perPageCheck'],
  popUp: ['perPageCheck'],
  preroll: ['placementIndexCheck'],
  midroll: ['placementIndexCheck'],
  maxPerVideo: {
    preroll: 5,
    midroll: 3
  }
};
