import styled from 'styled-components';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const StyledTableHead = withStyles(() => ({
  root: {
    backgroundColor: 'lightgrey'
  }
}))(TableHead);

const StyledTable = withStyles(() => ({
  root: {
    minWidth: 750
  }
}))(Table);

const ActionTableCell = withStyles(() => ({
  root: {
    width: '11%'
  }
}))(TableCell);

const CollapsibleTableCell = withStyles(() => ({
  root: {
    paddingBottom: 0,
    paddingTop: 0
  }
}))(TableCell);

const CollapsibleTableRow = withStyles(() => ({
  root: {
    background: 'ghostwhite'
  }
}))(TableRow);

const StyledTableCell = styled(TableCell)`
  cursor: ${props => (props.enablecollapse === 'true' ? 'pointer' : 'auto')};
  white-space: pre-line;
`;

const StyledTableDiv = styled.div`
  width: '100%';
`;

export {
  ActionTableCell,
  CollapsibleTableCell,
  CollapsibleTableRow,
  StyledTableDiv,
  StyledTableHead,
  StyledTable,
  StyledTableCell
};
