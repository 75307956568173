import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import CustomRow from './row';
import CustomTableHead from './header';
import { StyledTableDiv, StyledTable } from './styles';
import * as utils from '../../../utils/sorting';

export default function CustomTable(props) {
  const { list, headCells, features, rowSelected } = props;

  const [order, setOrder] = React.useState(features.defaultSortOrder);
  const [orderBy, setOrderBy] = React.useState(features.defaultSortColumn);
  const [selected, setSelected] = React.useState(rowSelected || []);

  useEffect(() => {
    setSelected(props.rowSelected || []);
  }, [props.rowSelected]);

  const handleSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    let newSelecteds = [];
    if (event.target.checked) {
      newSelecteds = list.map(r => r._id);
    }
    props.setCheckboxSelected(newSelecteds);
    setSelected(newSelecteds);
  };

  const handleRowClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    props.setCheckboxSelected(newSelected);
    setSelected(newSelected);
  };

  const handleEnabledCheck = (id, status) => {
    props.setEnableRowStatus(id, status);
  };

  const isSelected = name => {
    return selected.indexOf(name) !== -1;
  };

  const setCollapseRowIdStatus = statusObj => {
    props.setCollapseRowIdStatus(statusObj);
  };

  return (
    <StyledTableDiv>
      <Paper>
        <TableContainer>
          <StyledTable size="medium" aria-label="custom table" id={props.id}>
            <CustomTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleSort}
              rowCount={list.length}
              headCells={headCells}
              features={features}
            />
            <TableBody>
              {utils
                .stableSort(list, getComparator(order, orderBy, headCells))
                .map((row, index) => {
                  return (
                    <CustomRow
                      key={row._id}
                      isItemSelected={isSelected(row._id)}
                      handleClick={handleRowClick}
                      handleEnabledCheck={handleEnabledCheck}
                      row={row}
                      headCells={headCells}
                      features={features}
                      setCollapseRowIdStatus={setCollapseRowIdStatus}
                      collapsedRow={props.collapsedRow}
                    />
                  );
                })}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Paper>
    </StyledTableDiv>
  );
}

CustomTable.propTypes = {
  list: PropTypes.array.isRequired,
  features: PropTypes.object.isRequired,
  headCells: PropTypes.array.isRequired
};

function getComparator(order, orderBy, headCells) {
  const column = headCells.find(e => e.id === orderBy);
  const comparator =
    column && column.type === 'date'
      ? utils.descendingDateComparator
      : utils.descendingComparator;

  return order === 'desc'
    ? (a, b) => comparator(a[orderBy], b[orderBy])
    : (a, b) => -comparator(a[orderBy], b[orderBy]);
}
