import React from 'react';
import { FullWidthContainer, StyledTextField } from './styles';
import Multiselect from '../Common/Multiselect';
import { PAGES_TO_INCLUDE } from '../../constants';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

const pageToIncludetoFilterOut = ['homePage'];
class Discovery extends React.PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
  }

  handleTextInputChange = e => {
    this.handleInputChange(e.target.id, e.target.value);
  };

  handleInputChange = (id, value) => {
    this.props.handleChange({
      target: {
        type: 'discovery',
        id: id,
        value: value
      }
    });
  };

  filterPagesFromPagesConstant = pagesToInclude => {
    let pagesToIncludeOptions = cloneDeep(pagesToInclude);
    for (let i = 0; i < pageToIncludetoFilterOut.length; i++) {
      let index = pagesToIncludeOptions.findIndex(
        ele => ele.code === pageToIncludetoFilterOut[i]
      );
      pagesToIncludeOptions.splice(index, 1);
    }
    return pagesToIncludeOptions;
  };

  render() {
    const { fields } = this.props;
    const pageToIncludeOptions = this.filterPagesFromPagesConstant(
      PAGES_TO_INCLUDE
    );
    return (
      <div>
        <FullWidthContainer>
          <Multiselect
            labelId="pageToIncludeLabel"
            label="Pages to Include"
            elementId="pageToInclude"
            options={pageToIncludeOptions}
            multiple={true}
            setValue={this.handleInputChange}
            defaultValue={fields.pageToInclude || []}
          />
        </FullWidthContainer>
        <StyledTextField
          fullWidth
          id="targetLinkOnline"
          label="Target Link (Online)"
          margin="normal"
          onChange={this.handleTextInputChange}
          value={fields.targetLinkOnline}
          variant="outlined"
        />
        <StyledTextField
          fullWidth
          id="targetLinkOffline"
          label="Target Link (Offline)"
          margin="normal"
          onChange={this.handleTextInputChange}
          value={fields.targetLinkOffline}
          variant="outlined"
        />
      </div>
    );
  }
}

export default Discovery;
