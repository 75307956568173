import React, { useState, useEffect } from 'react';
import { FullWidthContainer } from './styles';
import VideoContentDropdowns from '../Common/VideoContentDropdowns';
import Checkbox from '@material-ui/core/Checkbox';
import { SubTitle, StyledCheckbox, TargetLinkDiv } from './styles';
import { get } from 'lodash';
import TextField from '@material-ui/core/TextField';
import {
  filterUniqueContentById,
  getContentIdsByCategory
} from '../../utils/helper';
import PlacementIndexTable from '../Common/PlacementIndexTable';
import { VIDEO_CATEGORIES, PLACEMENT_INDEX } from '../../constants';

const MidRoll = ({
  fields,
  handleChange,
  videoContent,
  airlineAllocation,
  error
}) => {
  const [isChecked, setIsChecked] = useState(fields.skipAd || false);
  const [videoContentList, setVideoContentList] = useState([]);
  const [airlinesSelected, setAirlinesSelected] = useState(airlineAllocation);

  useEffect(() => {
    setAirlinesSelected(airlineAllocation);
    setVideoContentList(videoContent);
  }, [JSON.stringify(airlineAllocation), videoContent]);

  const handleCheckboxChange = () => {
    handleChange({
      target: {
        type: 'midroll',
        id: 'skipAd',
        value: !isChecked
      }
    });

    setIsChecked(!isChecked);
  };

  const handleVideoContentChange = e => {
    handleChange({
      target: {
        type: 'midroll',
        id: e.target.id,
        value: e.target.value
      }
    });
  };

  const handleInputChange = e => {
    handleChange({
      target: {
        type: 'midroll',
        id: e.target.id,
        value: e.target.value
      }
    });
  };

  const createVideoContent = (videosCategoryData, airline) => {
    const { videosToInclude } = fields;
    let thisVideoContent = videosToInclude || [];
    const airlineChangedIndex = videosCategoryData.findIndex(
      obj => obj.airline === airline
    );

    getContentIdsByCategory(
      videosCategoryData[airlineChangedIndex].value,
      videosCategoryData[airlineChangedIndex].airline
    ).then(result => {
      const objIndex = thisVideoContent.findIndex(
        obj => obj.airline == airline
      );
      const newObj = { airline, contentId: result };
      if (objIndex === -1) {
        thisVideoContent.push(newObj);
      } else {
        thisVideoContent[objIndex] = newObj;
      }
      handleVideoContentChange({
        target: {
          id: 'videosToInclude',
          value: thisVideoContent
        }
      });
    });
  };

  const handleSelectChange = (id, value, airline) => {
    if (id === 'videosCategory') {
      createVideoContent(value, airline);
    }
    handleChange({
      target: {
        type: 'midroll',
        id: id,
        value: value
      }
    });
  };

  const getVideoCategory = videosCategory => {
    return videosCategory.slice(0, videosCategory.length - 1);
  };

  const getPlacementIndexOptions = placementIndex => {
    return placementIndex.slice(0, 3);
  };

  return (
    <FullWidthContainer>
      <StyledCheckbox>
        Allow user to skip video advertisement:
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          name="checkedB"
          color="primary"
        />
      </StyledCheckbox>

      {airlinesSelected.map(function(value, index) {
        return (
          <div>
            <PlacementIndexTable
              airline={value}
              handleSelectChange={handleSelectChange}
              fields={fields}
              options={getVideoCategory(VIDEO_CATEGORIES)}
              fieldName="videosCategory"
              label="Videos Category"
              numberOfSelectColumn={2}
              error={error}
              placementIndexOptions={getPlacementIndexOptions(PLACEMENT_INDEX)}
            />
          </div>
        );
      })}

      <SubTitle>Target Link (Online)</SubTitle>

      <TextField
        fullWidth
        id="targetLinkOnlineInstall"
        label="Target Link (Install)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOnlineInstall}
        variant="outlined"
      />

      <TargetLinkDiv>
        <TextField
          id="targetLinkOnlineLearnMore.label"
          label="Target Link Label"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOnlineLearnMore.label', 'Learn More')}
          variant="outlined"
        />

        <TextField
          id="targetLinkOnlineLearnMore.link"
          label="Target Link (Learn More)"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOnlineLearnMore.link', '')}
          variant="outlined"
        />
      </TargetLinkDiv>

      <TextField
        fullWidth
        id="targetLinkOnlineKnowMore"
        label="Target Link (Know More)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOnlineKnowMore}
        variant="outlined"
      />

      <SubTitle>Target Link (Offline)</SubTitle>

      <TextField
        fullWidth
        id="targetLinkOfflineInstall"
        label="Target Link (Install)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOfflineInstall}
        variant="outlined"
      />

      <TargetLinkDiv>
        <TextField
          id="targetLinkOfflineLearnMore.label"
          label="Target Link Label"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOfflineLearnMore.label', 'Learn More')}
          variant="outlined"
        />

        <TextField
          id="targetLinkOfflineLearnMore.link"
          label="Target Link (Learn More)"
          margin="normal"
          onChange={handleInputChange}
          value={get(fields, 'targetLinkOfflineLearnMore.link', '')}
          variant="outlined"
        />
      </TargetLinkDiv>

      <TextField
        fullWidth
        id="targetLinkOfflineKnowMore"
        label="Target Link (Know More)"
        margin="normal"
        onChange={handleInputChange}
        value={fields.targetLinkOfflineKnowMore}
        variant="outlined"
      />
    </FullWidthContainer>
  );
};

export default MidRoll;
