import axios from 'axios';
import { API_BASE_URL, MOBILE_API_BASE_URL, ERROR_MESSAGE } from '../constants';
import * as actions from './index';
import { get } from 'lodash';
import { AIRLINE_ALLOCAION_OPTIONS } from '../constants/advertisement';
import { responsiveFontSizes } from '@material-ui/core';
import { extractNameKeyFromArrayJson } from '../utils/helper';
import { logout } from './loginAction';

export const addNewAdvertisementAction = payload => {
  return dispatch => {
    dispatch(actions.addAdvertisementRequestStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'POST',
      url: `${API_BASE_URL}/advertisements`,
      data: payload,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.addAdvertisementRequestSuccess());
        } else {
          dispatch(actions.addAdvertisementRequestError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.addAdvertisementRequestError());
      });
  };
};

export const getAdvertisementById = id => {
  return dispatch => {
    dispatch(actions.getAdvertisementStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/advertisements/${id}`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.getAdvertisementSuccess(response.data));
        } else {
          dispatch(actions.getAdvertisementError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.getAdvertisementError());
      });
  };
};

export const editAdvertisementAction = payload => {
  return dispatch => {
    dispatch(actions.addAdvertisementRequestStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'PUT',
      url: `${API_BASE_URL}/advertisements/${payload._id}`,
      data: payload,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.addAdvertisementRequestSuccess());
        } else {
          dispatch(actions.addAdvertisementRequestError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.addAdvertisementRequestError());
      });
  };
};

export const deleteAdvertisementAction = id => {
  return dispatch => {
    dispatch(actions.deleteAdvertisementRequestStart());
    const token = localStorage.getItem('token');

    axios({
      method: 'DELETE',
      url: `${API_BASE_URL}/advertisements/${id}`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(actions.deleteAdvertisementRequestSuccess(id));
        } else {
          dispatch(actions.deleteAdvertisementRequestError());
        }
      })
      .catch(e => {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.deleteAdvertisementRequestError());
      });
  };
};

export const getAdvertismentConfigData = () => {
  return dispatch => {
    dispatch(actions.getAdvertisementConfigDataStart());
    const token = localStorage.getItem('token');
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/advertisement-config`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.getAdvertisementConfigDataSuccess(response.data));
        } else {
          dispatch(actions.getAdvertisementConfigDataError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.getAdvertisementConfigDataError());
      });
  };
};

export const getAdvertismentVideoContent = () => {
  return dispatch => {
    dispatch(actions.getVideoContentStart());

    let tasks = [];
    let airlines = [];

    AIRLINE_ALLOCAION_OPTIONS.forEach(airline => {
      airlines.push(airline.name);

      tasks.push(
        axios({
          method: 'GET',
          url: `${MOBILE_API_BASE_URL}/api/v1/catalogs/video/${airline.name}`
        })
      );
    });

    return Promise.all(tasks)
      .then(response => {
        const payload = {};

        for (let i = 0; i < response.length; i++) {
          let res = response[i];

          if (res.status === 200) {
            payload[airlines[i]] = res.data.map(videoContent => ({
              code: videoContent.id,
              name: get(videoContent, 'labels.title.eng', videoContent.id)
            }));
          } else {
            dispatch(actions.getVideoContentError(res.status));
            return;
          }
        }

        dispatch(actions.getVideoContentSuccess(payload));
      })
      .catch(err => dispatch(actions.getVideoContentError(err)));
  };
};

export const getContentData = () => {
  return dispatch => {
    dispatch(actions.getContentDataStart());
    const token = localStorage.getItem('token');
    const airlines = extractNameKeyFromArrayJson(AIRLINE_ALLOCAION_OPTIONS);
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/content/app?airlines=${JSON.stringify(airlines)}`,
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(function(response) {
        if (response.status === 200) {
          dispatch(actions.getContentDataSuccess(response.data));
        } else {
          dispatch(actions.getContentDataError());
        }
      })
      .catch(function(e) {
        if (e.toJSON().message === ERROR_MESSAGE['401']) {
          dispatch(logout());
        }
        dispatch(actions.getContentDataError());
      });
  };
};
