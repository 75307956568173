import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ViewListIcon from '@material-ui/icons/ViewList';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '../Common/Dialog';
import CustomTable from '../Common/Table/index';

import isEmpty from 'lodash/isEmpty';
//Used only for login api call now -- has to be removed

import { isObjectFieldsNotEmpty } from '../../utils/validations';

import Toaster from '../Common/Toaster';
import {
  FormStyles,
  Container,
  FormContainer,
  StyledButton,
  ViewHeader
} from './styles';

import AddEditUser from './AddEditUser';

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      closeForm: true,
      list: true,
      users: [],
      editRow: {},
      openAddEditDialog: false,
      formFields: {},
      toasterMessage: '',
      showToaster: false,
      toasterType: '',
      userManagement: false
    };
  }

  componentDidMount() {
    if (!this.props.user.roles || isEmpty(this.props.user.roles)) {
      this.props.getUserRoles();
    }
    if (this.props.login && this.props.login.loginStatus != true) {
      this.props.history.push('/view/login');
    } else {
      this.getUsersAndDisplay();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.listUsersStatus !== this.props.user.listUsersStatus) {
      this.processListResponse();
    }

    if (prevProps.login.loginStatus !== this.props.login.loginStatus) {
      if (this.props.login.loginStatus !== true) {
        this.props.history.push('/view/login');
      }
    }

    if (
      this.props.user.addUserStatus != prevProps.user.addUserStatus &&
      this.props.user.addUserStatus === true
    ) {
      this.changeAddUserFormCloseStatus();
    }
  }

  processListResponse = () => {
    if (this.props.user.listUsersStatus === true) {
      this.setState({
        users: this.props.user.users,
        list: true
      });
    }
  };

  setFormFields = fields => {
    this.setState({
      formFields: fields
    });
  };

  changeAddUserFormCloseStatus = type => {
    if (
      type === 'back' &&
      isObjectFieldsNotEmpty(this.state.formFields, {}) === true
    ) {
      this.setState({
        openAddEditDialog: true
      });
    } else {
      this.setState({
        closeForm: true,
        list: false,
        editRow: {},
        openAddEditDialog: false,
        formFields: {}
      });
      this.getUsersAndDisplay();
    }
  };

  changeAddUserOpenStatus = () => {
    this.setState({
      closeForm: false,
      list: false
    });
  };

  handleConformarionDialogOkay = () => {
    this.setState({
      openAddEditDialog: false
    });
  };

  getUsersAndDisplay = () => {
    this.props.listUsers();
  };

  handleusersRowEdit = row => {
    this.setState({
      editRow: row,
      closeForm: false,
      list: false
    });
  };

  handleToasterClose = () => {
    this.setState({
      showToaster: false
    });
  };

  openCampaign = () => {
    this.props.history.push('/view/campaign');
  };

  renderAddUserButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<AddIcon />}
        onClick={this.changeAddUserOpenStatus}
        id="add_cmp_btn"
      >
        Add User
      </StyledButton>
    );
  }

  renderLogoutButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        startIcon={<ExitToAppIcon />}
        onClick={this.props.dispatchLogout}
        id="logout"
      >
        Logout
      </StyledButton>
    );
  }

  renderCampaignButton() {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        startIcon={<ViewListIcon />}
        onClick={this.openCampaign}
        id="logout"
      >
        Campaigns
      </StyledButton>
    );
  }

  setUserEnableStatus = (id, status) => {
    const fields = { blocked: status };
    this.props.updateUser(id, fields);
  };

  render = () => {
    const { user } = this.props;

    const {
      users,
      editRow,
      showToaster,
      toasterType,
      toasterMessage
    } = this.state;

    const headerCells = [
      { id: 'username', type: 'text', label: 'Name', sortable: true },
      { id: 'email', type: 'text', label: 'Email Address', sortable: false },
      { id: 'role', type: 'text', label: 'Access', sortable: true }
    ];

    const features = {
      enableCheckbox: false,
      enableActions: true,
      enableCollapse: false,
      enableEnabledCheckbox: true,
      enableDeleteAction: false,
      editActionHandler: this.handleusersRowEdit,
      defaultSortColumn: 'username',
      defaultSortOrder: 'asc'
    };

    return (
      <Container>
        <Toaster
          message={toasterMessage}
          type={toasterType}
          open={showToaster}
          handleClose={this.handleToasterClose}
        />
        {this.state.closeForm ? (
          <div>
            <Grid container spacing={0}>
              <Grid item xs={6} align="left">
                <ViewHeader>User Management</ViewHeader>
              </Grid>
              <Grid item xs={6} align="right">
                {this.renderCampaignButton()}
                {this.renderLogoutButton()}
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6} align="left">
                <Grid container spacing={0}>
                  <Grid item xs={0} align="left">
                    {this.renderAddUserButton()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : null}
        {!this.state.closeForm ? (
          <FormContainer>
            <div
              className="right"
              onClick={() => this.changeAddUserFormCloseStatus('back')}
              id="add_cmp_close_btn"
            >
              <CloseIcon />
            </div>
            <AddEditUser
              user={user}
              setFormFields={this.setFormFields}
              addUser={this.props.addUser}
              editUser={this.props.updateUser}
              changeAddUserFormCloseStatus={this.changeAddUserFormCloseStatus}
              editRow={editRow}
            />
          </FormContainer>
        ) : null}
        {this.state.list ? (
          <CustomTable
            list={users}
            headCells={headerCells}
            features={features}
            setEnableRowStatus={this.setUserEnableStatus}
            id="user_list"
          />
        ) : null}
        {this.state.openAddEditDialog ? (
          <Dialog
            open={this.state.openAddEditDialog}
            message="You have made some changes to the fields. Do you want to save it?"
            handleClose={this.changeAddUserFormCloseStatus}
            handleOkay={this.handleConformarionDialogOkay}
            closeText="close"
            okayText="ok"
          />
        ) : null}
      </Container>
    );
  };
}

export default withStyles(FormStyles)(UserManagement);
