import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import { validationsList } from './advertisementTypeValidationsList';
import advertisementTypeValidations from './advertisementTypeValidations';

export const isBlank = value => {
  return (
    isNil(value) ||
    (((typeof value === 'string' && isEmpty(value.trim())) ||
      (isArray(value) && isEmpty(value))) &&
      !isNumber(value)) ||
    isNaN(value)
  );
};

export const isLessDateCheck = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  if (date1 >= date2) {
    return true;
  }
  return false;
};

export const isObjectFieldsNotEmpty = (obj, options) => {
  let filterKey = '';
  if (options.filter) {
    filterKey = options.filter;
  }
  for (let prop in obj) {
    if (prop !== filterKey && obj[prop] && obj[prop].length !== 0) {
      return true;
    }
  }

  return false;
};

export const isValidDate = date => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const advertisementTypeValuesValidationCheck = (
  fields,
  preOccupiedValueObj
) => {
  let validations = validationsList[fields.advertisementType] || [];
  let error = {};
  for (let i = 0; i < validations.length; i++) {
    error[fields.advertisementType] = advertisementTypeValidations[
      validations[i]
    ](fields, preOccupiedValueObj);
  }
  return error;
};
