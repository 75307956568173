import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Login from './Login';
import { loginAction } from '../../actions/loginAction';
const mapStateToProps = state => {
  const { login } = state;
  return {
    login
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchLogin(username, password) {
    dispatch(loginAction(username, password));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
