import React, { useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { useStyles, StyledSelect, StyledFormControl } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 250
    }
  }
};

export default function MultipleSelect(props) {
  const [valueName, setValueName] = React.useState(props.defaultValue);
  const {
    elementId,
    labelId,
    label,
    options,
    multiple,
    error,
    defaultValue,
    disabled
  } = props;

  useEffect(() => {
    setValueName(props.defaultValue);
  }, [props]);

  const handleChange = event => {
    setValueName(event.target.value);
    props.setValue(elementId, event.target.value);
  };

  const selectedValue = value => {
    let displayValue = '';
    {
      options.map((key, index) => {
        if (value.indexOf(key.code || key.name) > -1) {
          displayValue = displayValue + key.name + ', ';
        }
      });
    }

    return displayValue.substring(0, displayValue.length - 2);
  };

  return (
    <StyledFormControl variant="outlined">
      <InputLabel id={labelId}>{label}</InputLabel>
      <StyledSelect
        labelId={labelId}
        label={label}
        id={elementId}
        multiple={multiple}
        value={valueName}
        defaultValue={defaultValue}
        onChange={handleChange}
        inputProps={{
          name: `select_${elementId}`,
          id: `select_${elementId}`
        }}
        renderValue={selectedValue}
        MenuProps={MenuProps}
        autoWidth={true}
        error={error}
        disabled={disabled}
      >
        {options.map((key, index) => (
          <MenuItem key={index} value={key.code || key.name}>
            <Checkbox checked={valueName.indexOf(key.code || key.name) > -1} />
            <ListItemText primary={key.name} />
          </MenuItem>
        ))}
      </StyledSelect>
      {error && (
        <FormHelperText error={true}>Please enter {label}</FormHelperText>
      )}
    </StyledFormControl>
  );
}
