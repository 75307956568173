import findIndex from 'lodash/findIndex';

export const setPlacementErrorField = (data, field) => {
  let error = {};
  let airlineData = {};
  data.forEach(function(d, index) {
    if (!airlineData[d.preOccupiedObj.airline]) {
      airlineData[d.preOccupiedObj.airline] = [];
    }
    airlineData[d.preOccupiedObj.airline].push(d.key);
  });
  error[field] = airlineData;
  return error;
};

export const setErrorFieldTrue = (data, field) => {
  let error = {};
  error[field] = true;
  return error;
};

export const setVideoContentFieldErrorTrue = data => {
  let error = {};
  data.forEach(function(d, index) {
    const airline = d.preOccupiedObj.airline;
    error[`videosToInclude${airline}`] = true;
  });
  return error;
};

const getVideoTitleFromValue = (data, adConfig) => {
  const { value, preOccupiedObj } = data;
  const { airline } = preOccupiedObj;

  const { videoContent } = adConfig;

  if (!videoContent) {
    return;
  }

  let title = null;

  const videos = videoContent[airline];
  const selectedVideo = videos.filter(function(video, i) {
    return video.code === value;
  });

  if (selectedVideo[0]) {
    title = selectedVideo[0].name;
  }
  return title;
};

export const getErrorMessage = (d, field, adConfig, fields) => {
  if (field === 'placementIndex') {
    return `Value ${d.value} for Placement Index is already take in Campaign ${d.preOccupiedObj.campaignName} for advertisement ${d.preOccupiedObj.advertisementName} for airline ${d.preOccupiedObj.airline}`;
  }
  if (field === 'pageToInclude') {
    if (d.data.type === 'startDate') {
      return `Value ${d.value} for Pages to include is already take in Campaign ${d.preOccupiedObj.campaignName} for advertisement ${d.preOccupiedObj.advertisementName} with start date difference by ${d.data.diff} days`;
    }
    if (d.data.type === 'endDate') {
      return `Value ${d.value} for Pages to include is already take in Campaign ${d.preOccupiedObj.campaignName} for advertisement ${d.preOccupiedObj.advertisementName} with end date difference by ${d.data.diff} days`;
    }
    return `Value ${d.value} for Pages to include is already take in Campaign ${d.preOccupiedObj.campaignName} for advertisement ${d.preOccupiedObj.advertisementName} within same date range`;
  }

  if (field === 'videosToInclude') {
    let title = getVideoTitleFromValue(d, adConfig);
    return `Value ${title || d.value} for Videos to include for airline ${d
      .preOccupiedObj.airline ||
      ' '} is reached Maximum count within same date range`;
  }

  if (field === 'maxPlacementCountPerCategory') {
    return `Placement Index ${d.value} for category ${d.key} for airline ${d.preOccupiedObj.airline} is already taken in 3 advertisement before `;
  }
};

const filterOutExistingPlacementIndexValues = (extendedFields, editRow) => {
  if (
    !extendedFields[extendedFields.advertisementType].placementIndex ||
    !editRow[extendedFields.advertisementType].placementIndex
  ) {
    return;
  }

  let thisPlacementIndex =
    extendedFields[extendedFields.advertisementType].placementIndex;
  let editPlacementIndex =
    editRow[extendedFields.advertisementType].placementIndex;

  let airlines = Object.keys(thisPlacementIndex);
  for (let i = 0; i < airlines.length; i++) {
    let airline = airlines[i];
    let thisPlacementIndexArray = thisPlacementIndex[airline];
    let editPlacementIndexArray = editPlacementIndex[airline];

    for (let j = 0; j < thisPlacementIndexArray.length; j++) {
      let index = findIndex(editPlacementIndexArray, {
        key: thisPlacementIndexArray[j].key,
        value: thisPlacementIndexArray[j].value
      });

      if (index != -1) {
        thisPlacementIndexArray.splice(j, 1);
        j--;
      }
    }
  }
};

const filterOutExistingValuesForFooterPopupType = (extendedFields, editRow) => {
  if (
    !extendedFields[extendedFields.advertisementType].pageToInclude ||
    !editRow[extendedFields.advertisementType].pageToInclude
  ) {
    return;
  }

  if (!extendedFields.airlineAllocation || !editRow.airlineAllocation) {
    return;
  }

  if (
    extendedFields.airlineAllocation.length ===
      editRow.airlineAllocation.length &&
    extendedFields.airlineAllocation.every(
      (val, index) => val === editRow.airlineAllocation[index]
    )
  ) {
    let thisPageToInclude =
      extendedFields[extendedFields.advertisementType].pageToInclude;
    let editPageToInclude =
      editRow[extendedFields.advertisementType].pageToInclude;
    for (let i = 0; i < thisPageToInclude.length; i++) {
      if (editPageToInclude.indexOf(thisPageToInclude[i]) != -1) {
        thisPageToInclude.splice(i, 1);
        i--;
      }
    }
  }
};

const filterOutExistingVideoContentValues = (extendedFields, editRow) => {
  if (
    !extendedFields[extendedFields.advertisementType].videosToInclude ||
    !editRow[extendedFields.advertisementType].videosToInclude
  ) {
    return;
  }
  let thisVideosToInclude =
    extendedFields[extendedFields.advertisementType].videosToInclude;
  let editVideosToInclude =
    editRow[extendedFields.advertisementType].videosToInclude;

  for (let i = 0; i < thisVideosToInclude.length; i++) {
    const { airline, contentId } = thisVideosToInclude[i];
    const editIndex = findIndex(editVideosToInclude, {
      airline: airline
    });
    if (editIndex === -1) {
      continue;
    }
    let editVideo = editVideosToInclude[editIndex];
    if (!editVideo || !editVideo.contentId) {
      continue;
    }
    for (let j = 0; j < contentId.length; j++) {
      const index = editVideo.contentId.indexOf(contentId[j]);
      if (index != -1) {
        contentId.splice(j, 1);
        j--;
      }
    }
  }
};

export const filterPreExstingValuesFromCheck = (extendedFields, editRow) => {
  if (
    !extendedFields[extendedFields.advertisementType] ||
    !editRow[extendedFields.advertisementType]
  ) {
    return;
  }

  if (
    extendedFields.advertisementType === 'leaderboard' ||
    extendedFields.advertisementType === 'preroll' ||
    extendedFields.advertisementType === 'midroll'
  ) {
    filterOutExistingPlacementIndexValues(extendedFields, editRow);
  }

  if (
    extendedFields.advertisementType === 'footer' ||
    extendedFields.advertisementType === 'popUp'
  ) {
    filterOutExistingValuesForFooterPopupType(extendedFields, editRow);
  }
};
