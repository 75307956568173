// campaigns
export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_ERROR = 'ADD_CAMPAIGN_ERROR';
export const ADD_CAMPAIGN_REQUEST_START = 'ADD_CAMPAIGN_REQUEST_START';

export const LIST_CAMPAIGN_SUCCESS = 'LIST_CAMPAIGN_SUCCESS';
export const LIST_CAMPAIGN_ERROR = 'LIST_CAMPAIGN_ERROR';
export const LIST_CAMPAIGN_REQUEST_START = 'LIST_CAMPAIGN_REQUEST_START';

export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';
export const DELETE_CAMPAIGN_REQUEST_START = 'DELETE_CAMPAIGN_REQUEST_START';

// advertisements
export const ADD_ADVERTISEMENT_SUCCESS = 'ADD_ADVERTISEMENT_SUCCESS';
export const ADD_ADVERTISEMENT_ERROR = 'ADD_ADVERTISEMENT_ERROR';
export const ADD_ADVERTISEMENT_REQUEST_START =
  'ADD_ADVERTISEMENT_REQUEST_START';

export const DELETE_ADVERTISEMENT_SUCCESS = 'DELETE_ADVERTISEMENT_SUCCESS';
export const DELETE_ADVERTISEMENT_ERROR = 'DELETE_ADVERTISEMENT_ERROR';
export const DELETE_ADVERTISEMENT_REQUEST_START =
  'DELETE_ADVERTISEMENT_REQUEST_START';

export const GET_ADVERTISEMENT_SUCCESS = 'GET_ADVERTISEMENT_SUCCESS';
export const GET_ADVERTISEMENT_ERROR = 'GET_ADVERTISEMENT_ERROR';
export const GET_ADVERTISEMENT_START = 'DELETE_ADVERTISEMENT_START';

export const GET_ADDVERTISEMENT_CONFIG_DATA_REQUEST_START =
  'GET_ADDVERTISEMENT_CONFIG_DATA_REQUEST_START';
export const GET_ADDVERTISEMENT_CONFIG_DATA_SUCCESS =
  'GET_ADDVERTISEMENT_CONFIG_DATA_SUCCESS';
export const GET_ADDVERTISEMENT_CONFIG_DATA_ERROR =
  'GET_ADDVERTISEMENT_CONFIG_DATA_ERROR';

export const GET_VIDEO_CONTENT_REQUEST_START =
  'GET_VIDEO_CONTENT_REQUEST_START';
export const GET_VIDEO_CONTENT_SUCCESS = 'GET_VIDEO_CONTENT_SUCCESS';
export const GET_VIDEO_CONTENT_ERROR = 'GET_VIDEO_CONTENT_ERROR';

export const GET_CONTENT_DATA_REQUEST_START = 'GET_CONTENT_DATA_REQUEST_START';
export const GET_CONTENT_DATA_SUCCESS = 'GET_CONTENT_DATA_SUCCESS';
export const GET_CONTENT_DATA_ERROR = 'GET_CONTENT_DATA_ERROR';

export const SYNC_CAMPAIGN_REQUEST_START = 'SYNC_CAMPAIGN_REQUEST_START';
export const SYNC_CAMPAIGN_SUCCESS = 'SYNC_CAMPAIGN_SUCCESS';
export const SYNC_CAMPAIGN_ERROR = 'SYNC_CAMPAIGN_ERROR';

export const SET_ALREADY_OCCUPIED_ADVERTISEMENT_VALUES =
  'SET_ALREADY_OCCUPIED_ADVERTISEMENT_VALUES';

export const LOGIN_REQUEST_START = 'LOGIN_REQUEST_START';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_ERROR = 'LOGIN_REQUEST_ERROR';

export const USER_ROLE_START = 'USER_ROLE_START';
export const USER_ROLE_SUCCESS = 'USER_ROLE_SUCCESS';
export const USER_ROLE_ERROR = 'USER_ROLE_ERROR';

export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const LIST_USERS_START = 'LIST_USERS_START';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const LIST_USERS_ERROR = 'LIST_USERS_ERROR';

export const SET_CAMPAIGN_COLLAPSE_ROW = 'SET_CAMPAIGN_COLLAPSE_ROW';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
